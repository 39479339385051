import styled from "styled-components";
import { defineBreaks } from "../../primitives/responsive";

export default styled.button`
  position: relative;
  margin: 0 auto;

  border: none;
  background: transparent;
  transition: opacity 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  border-radius: 40px;

  background: #435c73;
  color: #f2e6d8;
  font-weight: bold;

  ${defineBreaks("width", "200px", [{ breakPoint: "600px", value: "350px" }])}

  ${defineBreaks("font-size", "1.5rem", [
    { breakPoint: "600px", value: "2.5rem" },
  ])}

  ${defineBreaks("padding", "10px 20px", [
    { breakPoint: "600px", value: "10px 40px" },
  ])}

  &:hover {
    opacity: 0.75;
  }
`;
