import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { useState, useEffect } from "react";

// first-party constant imports and styling
import { fetchWallet } from "../../state/actions";
import ConnectButton from "./metaMask.style";

export const Connect = ({
  className = "",
  divider = " ... ",
}: {
  className?: string;
  divider?: string;
}) => {
  const wallet = useSelector((state: RootStateOrAny) => state.wallet);
  const [label, setLabel] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const truncateAddress = (address: string) => {
      try {
        return `${address.slice(0, 5)} ${divider} ${address.slice(-5)}`;
      } catch (error) {
        return ``;
      }
    };

    switch (wallet?.address) {
      case undefined:
        setLabel("Connect Wallet");
        break;
      case false:
        setLabel("Connect Wallet");
        break;
      case null:
        setLabel("Connect Wallet");
        break;
      default:
        setLabel(truncateAddress(wallet?.address));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const connectWallet = async () => {
    if (wallet?.ethereum) {
    } else {
      dispatch(fetchWallet());
    }
  };

  return (
    <ConnectButton
      className={`${className} ${wallet?.address ? "active" : ""}`}
      onClick={connectWallet}
    >
      {label}
    </ConnectButton>
  );
};
