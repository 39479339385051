import styled from "styled-components";
import { defineBreaks } from "../../../../primitives/responsive";

export const PurchaseButton = styled.button`
  position: relative;
  margin: 0 auto;

  border: none;
  background: transparent;
  transition: all 0.25s ease-in-out;

  font-size: 3rem;
  font-weight: 300;

  background: white;
  color: black;
  border-radius: 20px;
  padding: 25px 30px;
  min-width: 300px;
  user-select: none;

  @media screen and (max-width: 1920px), screen and (max-height: 1080px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 1280px), screen and (max-height: 720px) {
    font-size: 1.5rem;
    min-width: 150px;
    padding: 20px 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
    padding: 15px 0;
  }

  &:hover {
    background: transparent;
    color: white;
    text-shadow: 0 0 2px rgba(42, 42, 42, 0.5);
  }
`;

export const Title = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
  max-width: 80%;
  text-shadow: 0 0 2px rgba(42, 42, 42, 0.5);
  margin-bottom: 20px;

  ${defineBreaks("font-size", "1.1rem", [
    { breakPoint: "480px", value: "1.5rem" },
    { breakPoint: "1280px", value: "1.75rem" },
    { breakPoint: "1920px", value: "2rem" },
  ])}

  &.not-whitelisted {
    margin-bottom: 25px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1rem;
  color: white;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  line-height: 1rem;
  max-width: 90%;
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 0 0 2px rgba(42, 42, 42, 0.5);
`;

export const Center = styled.div<any>`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;

  height: ${(props) => props.height};

  div.bunny {
    aspect-ratio: 1;
    border-radius: 50%;
    background-image: url(https://cdn.pr0xy.io/powpow/bunny.svg);
    background-size: 113%;
    box-shadow: 0 0 0 5px white, 0 0 0 10px #f2b6bb;
    background-position: center;

    ${defineBreaks("margin", "10px auto 25px auto", [
      { breakPoint: "600px", value: "0 auto 50px auto" },
    ])}

    ${defineBreaks("height", "175px", [
      { breakPoint: "600px", value: "250px" },
    ])}
  }

  h1 {
    margin: 0;
    font-weight: bold;
    display: flex;
    align-items: center;

    ${defineBreaks("font-size", "2rem", [
      { breakPoint: "600px", value: "4rem" },
    ])}

    ${defineBreaks("height", "80px", [{ breakPoint: "600px", value: "150px" }])}
  }

  h2 {
    margin: 0;
    font-weight: bold;
    display: flex;

    ${defineBreaks("height", "45px", [{ breakPoint: "600px", value: "60px" }])}

    ${defineBreaks("font-size", "1.8rem", [
      { breakPoint: "600px", value: "3rem" },
    ])}

    &:not(:first-child) {
      ${defineBreaks("margin-bottom", "0", [
        { breakPoint: "600px", value: "30px" },
      ])}
    }
  }
`;

Center.defaultProps = {
  height: "auto",
};

export const Selector = styled.div`
  background: white;
  background: #435c73;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  user-select: none;
  color: #f2e6d8;
  font-weight: 800;
  font-size: 50px;
  line-height: 50px;

  width: 50px;
  height: 50px;

  span {
    transform: translateY(-4px);
  }

  &:hover {
    opacity: 0.75;
  }
`;

export const PurchaseSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;

  ${defineBreaks("width", "300px", [{ breakPoint: "600px", value: "400px" }])}
`;

const SelectTitle = styled.p`
  text-transform: lowercase;
  font-size: 1.25rem !important;
  letter-spacing: 0.5px;
  border: 3px solid #435c73;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 700;
  margin-bottom: 25px;
`;

const Amount = styled.span`
  padding: 0;
  margin: 0;
  font-size: 3rem;
  font-weight: bold;
`;

const MintButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  color: #f2e6d8;
  background: #435c73;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  margin-top: 25px;

  &:hover {
    opacity: 0.75;
  }
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 1.25rem;
`;

const Components = {
  SelectTitle,
  MintButton,
  Amount,
  Price,
};

export default Components;
