import styled from "styled-components";
import { defineBreaks } from "../../../../primitives/responsive";

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${defineBreaks("margin-top", "25px", [
    { breakPoint: "600px", value: "50px" },
  ])}

  img {
    margin: 0 auto;
    margin-bottom: 15px;

    ${defineBreaks("width", "60px", [{ breakPoint: "600px", value: "100px" }])}
  }

  h1 {
    padding: 0;
    margin: 0;
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: 40px;
    cursor: pointer;
    transition: opacity 250ms ease-in-out;

    ${defineBreaks("font-size", "1.5rem", [
      { breakPoint: "600px", value: "2.5rem" },
    ])}

    :hover {
      opacity: 0.75;
    }
  }
`;
