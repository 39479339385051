import { useSelector } from "react-redux";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { stringWithId } from "../../../../state/atoms/withId";
import { objectWithId } from "../../../../state/atoms/contractState";
import { Center } from "./Mint.style";

import SoldOut from "./Stages/SoldOut";
import PublicSale from "./Stages/PublicSale";
import PreSale from "./Stages/PreSale";

require("dotenv").config();

export const AmountSelectorDiv = ({ mintNFT }: { mintNFT: any }) => {
  const state = useRecoilValue<any>(objectWithId("sale-state"));
  const { wallet } = useSelector((state: any) => state);
  const err = "Having issues? Try reconnecting your wallet, or use CTRL+F5.";
  const setGlobalError = useSetRecoilState(stringWithId("error", err));

  /**
   * @title Sold Out
   * @description occurs when the project has sold out
   */
  if (Number(state.minted) >= Number(state.supply) && state.supply) {
    setGlobalError("");
    return <SoldOut />;
  }

  /**
   * @title Blacklisted
   * @description occurs when the user has fully minted, and/or is blacklisted from the sale
   */
  if (
    state.blacklisted &&
    state.status > 0 &&
    wallet?.address &&
    state.whitelisted
  ) {
    return (
      <Center>
        <h2>Yippee!</h2>
        <h2>Your mint was successful!</h2>
        <div className="bunny" />
      </Center>
    );
  }

  /**
   * @title Public Sale
   * @description occurs when the public sale is active
   */
  if (
    state.status === 1 &&
    state.siteRoot === state.contractRoot &&
    wallet?.address &&
    state.blacklisted === false
  ) {
    return (
      <Center>
        <h1>Mint Your PowPow!</h1>
        <PreSale state={state} mintNFT={mintNFT} />
      </Center>
    );
  }

  /**
   * @title Public Sale
   * @description occurs when the public sale is active
   */
  if (state.status === 2 && wallet?.address && state.blacklisted === false) {
    return (
      <Center>
        <h1>Mint Your PowPow!</h1>
        <PublicSale state={state} mintNFT={mintNFT} />
      </Center>
    );
  }

  if (["MetaMask", "Infura"].includes(wallet?.type)) {
    return (
      <Center>
        <h1>Mint Your PowPow!</h1>
        <div className="bunny" />
      </Center>
    );
  }

  return <></>;
};
