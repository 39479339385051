import _ from "lodash";
import { Pair } from "./responsive.d";

type PairType = typeof Pair;

/**
 * @title Generate Maximum
 * @description
 *
 * @param {} pair
 */
const generateMaximum = (pair: PairType): string => {
  if (_.isEmpty(pair)) return "";
  return `and (max-width: ${pair.breakPoint})`;
};

export const defineBreaks = (
  field: string,
  defaultValue: string,
  breaks: Array<PairType>
): string => {
  return `
    ${field}: ${defaultValue};

    ${_.map(breaks, ({ breakPoint, value }, index) => {
      return `
        @media screen and (min-width: ${breakPoint}) ${generateMaximum(
        breaks[index + 1]
      )} {
          ${field}: ${value};
        }
      `;
    }).join("")}
  `;
};
