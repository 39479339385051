import { Purchasing } from "./Purchasing";
import { BrowserRouter, Route } from "react-router-dom";
import * as actions from "../state/actions";
import { connect } from "react-redux";
import { RecoilRoot } from "recoil";
import { Component } from "react";

import styled from "styled-components";
import Socials from "./Socials";
import { defineBreaks } from "../primitives/responsive";

type MyProps = {
  fetchWallet: Function;
  wallet: {
    address: string;
    chain: number;
    ethereum: Object;
    web3: Object;
  };
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100vw;
  height: 100vh;

  ${defineBreaks("padding", "20px 0", [
    { breakPoint: "600px", value: "40px 0" },
  ])}
`;

/**
 * @title Primary App
 * ------------------
 * Creates the react class component used to display the front-end to the client. This is fed into
 * index.tsx.
 *
 * @notes
 * ------
 * Should probably also have a type declaration for the second attribute.
 */
class App extends Component<MyProps, any> {
  componentDidMount() {
    this.props.fetchWallet("initial", true);
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          <RecoilRoot>
            <Container>
              <Socials />
              <Route exact path="/" component={Purchasing} />
            </Container>
          </RecoilRoot>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    wallet: state.wallet,
  };
};

export default connect(mapStateToProps, actions)(App as any);
