import { presaleWL } from "./whitelist";

require("dotenv").config();

const mintNFT = async (
  state: any,
  amount: number,
  contract: any,
  setGlobalError: any,
  globalError: string,
  wallet: any,
  setAmount: any
) => {
  let logs = [];
  logs.push("@DEBUG: Starting mint process.");

  if (!wallet?.address) {
    return setGlobalError("Ethereum Address Not Detected");
  }

  let mintingFunction = contract.methods.presale;
  let args = [presaleWL.getProof(wallet?.address), amount, state.presaleLimit];

  if (state.status === 2) {
    mintingFunction = contract.methods.mint;
    args = [amount];
  }

  if (state.blacklisted === true) {
    return setGlobalError("You've already claimed.");
  }

  // const requiredChain = process.env.REACT_APP_NETWORK === "mainnet" ? 1 : 4;
  // if (wallet?.chain !== requiredChain) {
  //   return setGlobalError("You're not on mainnet.");
  // }

  if (globalError === "Transaction Pending.") {
    logs.push(`@DEBUG: User already has a pending TX. Check Metamask.`);
    console.log(logs.join("\n"));
    return setGlobalError("Transaction Pending.");
  }

  if (amount === 0) {
    return setGlobalError("You can't mint 0.");
  }

  const value = amount * state.price;

  try {
    // first we estimate the gas, giving us an idea of what should be used for the minting
    // process, this is included as an extra parameter into the `.send()` method for
    // increased accuracy in sending the transaction
    let gas = await mintingFunction(...args).estimateGas({
      from: wallet.address,
      value,
    });
    gas = Number.parseInt(`${gas * 1.2}`);

    // we retrieve the base gas from the latest block which is eventually used to determine
    // what the `maxFeePerGas` and `maxPriorityFeePerGas` should be
    let baseFeePerGas = await wallet.web3.eth.getBlock("latest");
    baseFeePerGas = baseFeePerGas.baseFeePerGas;

    // the `maxPriorityFeePerGas` is more difficult and a reference can be found here:
    // https://hackmd.io/@q8X_WM2nTfu6nuvAzqXiTQ/1559-wallets
    // this however, we set above the recommended to compensate for high gas volatility
    const maxPriorityFeePerGas = wallet.web3.utils.toWei("3", "gwei");

    // the maxFeePerGas is the equivalent of the `baseFeePerGas` and the `maxPriorityFeePerGas`
    // it seems to be suggested here to use 2 * base fee:
    // https://github.com/ethers-io/ethers.js/issues/1610
    // another reference considers finding the optimal values:
    // https://github.com/ethereum/web3.py/pull/2055
    const maxFeePerGas =
      Number.parseInt(maxPriorityFeePerGas) +
      2 * Number.parseInt(baseFeePerGas);

    logs.push(`@DEBUG: Gas Estimation: ${gas}`);
    logs.push(`@DEBUG: baseFeePerGas: ${baseFeePerGas}`);
    logs.push(`@DEBUG: maxPriorityFeePerGas: ${maxPriorityFeePerGas}`);
    logs.push(`@DEBUG: maxFeePerGas: ${maxFeePerGas}`);

    console.log(logs.join("\n"));

    // sending the transction in accordance to `EIP-1559`:
    // https://web3py.readthedocs.io/en/stable/web3.eth.html
    // https://docs.alchemy.com/alchemy/guides/eip-1559/maxpriorityfeepergas-vs-maxfeepergas
    // https://docs.alchemy.com/alchemy/guides/eip-1559/gas-estimator
    // https://docs.alchemy.com/alchemy/guides/eip-1559/send-tx-eip-1559
    try {
      setGlobalError("Transaction Pending.");

      await mintingFunction(...args).send({
        from: wallet.address,
        value,
        chainId: wallet.chain,
        gas,
        maxFeePerGas,
        maxPriorityFeePerGas,
        type: "0x2",
      });

      setAmount(0);
      setGlobalError("Transaction successful!");
    } catch (error: any) {
      console.log(error.message);
      if (error.message.includes("1559")) {
        try {
          await mintingFunction(...args).send({
            from: wallet.address,
            value,
            gas,
            type: "0x0",
          });

          setAmount(0);
          setGlobalError("Transaction successful!");
        } catch (err: any) {
          console.log(error.message);
          if (err.message.includes("User denied")) {
            return setGlobalError("You denied the transaction.");
          }

          return setGlobalError("eip-1559 failure");
        }
      } else if (error.message.includes("User denied")) {
        return setGlobalError("You denied the transaction.");
      } else if (error.message.includes("Mint Claimed")) {
        return setGlobalError("You've already claimed.");
      } else {
        return setGlobalError(`Unexpected Error: ${error.message}`);
      }
    }
  } catch (error: any) {
    if (error.message.includes("ADDRESS_MAX_REACHED")) {
      return setGlobalError("You've already claimed.");
    } else if (error.message.includes("LIVE")) {
      return setGlobalError("The claim is not yet live.");
    } else if (error.message.includes("param.map")) {
      return setGlobalError("You're not registered.");
    } else if (error.message.includes("insufficient funds")) {
      return setGlobalError("Insufficient funds. Must have enough for gas.");
    } else if (error.message.includes("Not Active")) {
      return setGlobalError("No sale is currently active.");
    } else if (error.message.includes("Amount Denied")) {
      return setGlobalError("You can't mint that many.");
    } else if (error.message.toLowerCase().includes("50 blocks")) {
      return setGlobalError("Your transaction is taking some extra time.");
    }

    return setGlobalError(`Unexpected Error: ${error.message}`);
  }
};

export default mintNFT;
