import styled from "styled-components";

export const Error = styled.div`
  width: 100%;
  text-align: center;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0 auto;

  font-size: 1.33rem;
  letter-spacing: 1px;
  color: rgb(255, 60, 60);
  text-shadow: 0 0 2px rgba(42, 42, 42, 0.5);

  @media screen and (max-width: 1280px), screen and (max-height: 720px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.75rem;
    max-width: 75%;
  }
`;

export default styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  overflow: scroll;
  overflow-x: hidden;
  width: min(1200px, 90%);

  border-radius: 60px;

  background: #f2e6d8;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
