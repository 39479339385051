import styled from "styled-components";
import { defineBreaks } from "../../primitives/responsive";

export const Socials = styled.div`
  position: relative;
  z-index: 1000000000;

  width: min(1200px, 90%);

  display: flex;
  justify-content: space-between;
  align-items: end;

  ${defineBreaks("margin-bottom", "20px", [
    { breakPoint: "600px", value: "40px" },
  ])}
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 2.5rem;
    transition: opacity 250ms ease-in-out;

    :hover {
      opacity: 0.75;
    }

    ${defineBreaks("font-size", "2rem", [
      { breakPoint: "600px", value: "3rem" },
    ])}
  }
`;

export const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
