import BannerStyle from "./Banner.style";

require("dotenv").config();

const Banner = () => {
  return (
    <BannerStyle>
      <img
        src={`https://cdn.pr0xy.io/powpow/flowers.svg`}
        className="flowers"
        alt="PowPow Flowers"
      />
    </BannerStyle>
  );
};

export default Banner;
