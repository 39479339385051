/**
 * @title Custom Mint Page
 */

// css and javascript imports from other packages, namely bootstrap and font-awesome
// importing them here allows them to be used in any associated javascript file
import "@fortawesome/fontawesome-pro/css/all.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/config.scss";

// importing modules necessary for global state management and react
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import ReactDOM from "react-dom";
import React from "react";

// importing first party scripts and styling
import App from "./components/App";
import reducers from "./state/reducers";

// the reducers import is used to create a redux store
const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
