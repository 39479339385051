import { atom } from "recoil";
import { memoize } from "lodash";

export const objectWithId = memoize((id) =>
  atom({
    key: `${id}`,
    default: {
      supply: null,
      minted: null,
      status: null,
      contractRoot: "0x0",
      price: null,
      publicLimit: null,
      numberMinted: null,
      setRoot: "0x0",
      presaleLimit: null,
      blacklisted: null,
    },
  })
);
