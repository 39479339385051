import { FETCH_WALLET } from "../actions/_types";

export const walletReducer = function (state = null, action: any) {
  switch (action.type) {
    case FETCH_WALLET:
      return action.payload || false;
    default:
      return state;
  }
};

export type RootStateOrAny = ReturnType<typeof walletReducer>;
