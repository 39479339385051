const objectZip = (keys: Array<any>, values: Array<any>) =>
  keys.reduce(
    (others, key, index) => ({
      ...others,
      [key]: values[index],
    }),
    {}
  );

const promiseObject = async (obj: Object) =>
  objectZip(Object.keys(obj), await Promise.all(Object.values(obj)));

export default promiseObject;
