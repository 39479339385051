import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { ethers } from "ethers";
import _ from "lodash";

type objectMerkleType = Array<{
  address: string;
  max: number;
}>;

class ObjectMerkleTree {
  whitelist: objectMerkleType;
  leaves: any;
  tree: any;
  root: any;

  constructor(name: string, whitelist: objectMerkleType) {
    this.whitelist = whitelist;

    // creating the leaves of the merkle tree using web3's solidityKeccak256 to account
    // for multiple input values
    this.leaves = this.whitelist.map((obj) => {
      return ethers.utils.solidityKeccak256(
        ["address", "uint"],
        [obj.address, obj.max]
      );
    });

    // creating the merkle tree which generates the root and the ability to proof
    this.tree = new MerkleTree(this.leaves, keccak256, { sortPairs: true });

    // exporting the merkle root for later use
    this.root = this.tree.getRoot().toString("hex");
    console.log(`@DEBUG: The (${name}) Merkle Root: 0x${this.root}`);
  }

  getProof(address: string) {
    const index = this.whitelist.findIndex(
      (wl) => wl.address.toLowerCase() === address.toLowerCase()
    );

    if (index === -1) return false;

    return this.tree.getHexProof(this.leaves[index]);
  }

  getMaxAmount(address: string) {
    const max = this.whitelist.filter(
      (wl) => wl.address.toLowerCase() === address.toLowerCase()
    );

    if (max.length === 0) return 0;

    return max[0].max;
  }

  isWhitelisted(address: string) {
    const addresses = _.map(this.whitelist, (wl) => wl.address.toLowerCase());
    return addresses.includes(address.toLowerCase());
  }
}

// * ===============================================================================================
// * WHITELISTS ====================================================================================
// * ===============================================================================================
const WHITELIST = [
  ["0x9120E3Ed387C63F91387f10b0DB0eE5E91603302", "1"], // deployer test
  ["0xACC043B57E9F867Cadcb915Ff2c0c3747Cc13FDd", "96"], // powpow treasury
  // ["0x02261e53B6Ee2c39fB6911d87964b0fBE9cfF81A", "4"],
  // ["0x16ABa4A311DC92C5bA3B033810BC66ADEDe9Ac71", "4"],
  // ["0xB67B95d9c36cf72e0aE2E38A0090f95126bE19D3", "4"],
  // ["0x51050ec063d393217B436747617aD1C2285Aeeee", "4"],
  // ["0x206ad35d0d8498b170d89c5419034c767bc098bb", "4"],
  // ["0x8aAce9dc712D312a32E32783C68Bb958117aF82B", "4"],
  // ["0x55A2A8863992aCec4fF28dA6ED29102A0316Daa6", "4"],
  // ["0x0be2cd6dEAEE51fA2a29183Fa82061C720fdd19a", "4"],
  // ["0x90f99FeA4275Ceddb169C631b5bfF96781CC8dCC", "4"],
  // ["0x60530cB6B5c2e8ddC791471ac229C9A66d8AE24E", "4"],
  // ["0xc21Fa011F5e802017C69eD5A0e4F6D94E404fAFE", "4"],
  // ["0x2cc42bE60e5328748aC4f87F39CEc09C6897825A", "4"],
  // ["0x59f239b30c07021B30bdAFa7a668E09F84a1777c", "4"],
  // ["0xA4d198E7262A01Fa5c16068d31134413Bb881400", "4"],
  // ["0xbcd8Af5C0d4B090a350841d8A87De62D072bA1E4", "4"],
  // ["0xa353cC8F831B6E1bdF8E4fE5167cEc569A9D332f", "4"],
  // ["0x9431D1615FA755Faa25A74da7f34C8Bd6963bd0A", "4"],
  // ["0xbe3580ab017137b827c7dcb4803cb35ec112bf1a", "4"],
  // ["0x4423C3C30C6C42E52dF3AbF70143BC73Aa6a22B2", "4"],
  // ["0xa8ff5ba66Bf96BAc81D81058D610b9b8c4cacff0", "4"],
  // ["0x74F7065e169CC98d8D877F397cf949132174d1Ca", "4"],
  // ["0x08A86e97a3817dBC27D848C61D69395feeaf85bD", "4"],
  // ["0xFbBF55D5DD18B9568918B936f5Da26b358C0A1d6", "4"],
  // ["0xD9E077E066125d7C97d57241b4671bBe36c7a861", "4"],
  // ["0x6e41192455B659f5b628598CEC6A2c888ee81126", "4"],
  // ["0xD13699179e49182fa4Ff7D27175234Ade36E3441", "4"],
  // ["0x6842c1FF6B2Bf0585A36918e56902b5F1555cC78", "4"],
  // ["0x5bf0c1a19Ea20169cF4332bf0f10cEb056D6442e", "4"],
  // ["0x0459B1d8C946700E45C11ED354b709AF97c177C9", "4"],
  // ["0x0a189E539F5e1944e236cA609AA7685faEEcd4F7", "4"],
  // ["0xABff540dd47C7985193AB97a841a2369Dc2eC5A9", "3"],
  // ["0xeCd6fbE0364086fDA1da9140F2963c5ABa6DC038", "3"],
  // ["0x62eddDC9f56323e585581207a789907F96Fa2cCa", "3"],
  // ["0x38a6A0da8C66467A3bE19e293FB6A7A10fA7b7d2", "3"],
  // ["0x83c03429C62379B6063757B00061c042323DE03e", "3"],
  // ["0xA236ea2194d56D54874730dcD2de9a39a47F57f9", "3"],
  // ["0xB0Ee8BC623a9D04EA960387aa51D26452eF10cFb", "3"],
  // ["0x25752c333e544a13A2b4887b5F5488817c28f2c9", "3"],
  // ["0x82Df9620224dc2491252c7d699C2515BD1d433A0", "3"],
  // ["0x73B41FAfc67fbee0Afd35EAEAba76e7468083f07", "3"],
  // ["0x0db1042C5427056707709b6A66d4f3345F74AF65", "3"],
  // ["0xed239741307c9906279dcaa4c6fb2632858018a7", "3"],
  // ["0xad540823b290181295e7f17434a1B422C9791C17", "3"],
  // ["0x3113194Dfbe3bF10fE5069B7eada689a4EBd8688", "3"],
  // ["0x78f3479c4f90CA882E1D54b99137c24487CDab93", "3"],
  // ["0xFbd017BDD29faeb97fb5e2311d69d3a3337bfB1c", "3"],
  // ["0xC5488672c5b8C8Ce50D651Ac3f608f1a61539788", "3"],
  // ["0x7ECF5D15862074d311c282E2b47aEeEdcFd20376", "3"],
  // ["0xc4341519A0aEA50CbddF0B06D91BdB166C2264e2", "3"],
  // ["0x56228F666699F2Bed25Df0931F9B8402Cb55CB72", "3"],
  // ["0xd322135FB340C2CB162c838e4006f474986cFcf2", "3"],
  // ["0x654acb9bF947dcE25F06c73976037270c460007D", "3"],
  // ["0xe9aE4bb555CbD899054122956C9aF6388Fd1E13F", "3"],
  // ["0xE1f6E4026Ea4C644bfBF2d91EB825e5589A98f0A", "3"],
  // ["0x1132997d1c2F4A19BE973552CE1A361dBFD7967d", "3"],
  // ["0x33d704D1347bBf81C05104bC41beE19e83C02205", "3"],
  // ["0xD7f2a0949f084FAFeCcBdadC1e3784793ab209d6", "3"],
  // ["0x4514A161e081B13cF5870Ca65a30B790997770F3", "3"],
  // ["0xD9eBBEe97585c2cdBcdc7aE6B37fE6BCeCef55A1", "3"],
  // ["0x96B258360501863aD18567fCeb41eC3e504f0690", "3"],
  // ["0x65FA76C137ECD47d7674c769E4b779bABD940DD4", "3"],
  // ["0x6b13eD23FDd1331E87B30Bb90C03596B2E4DDe9f", "3"],
  // ["0x143374081665E9bF65F73C2b8c6265D99E56aBfd", "3"],
  // ["0x6Ad5189285aaA6C4D0B658A192A2629a59c1c1ca", "3"],
  // ["0xeF8b9aEc830d3D9D8633A58F78224e0Ab7e6Fda1", "3"],
  // ["0x643e68eE3602AFA278D7794Ac495d72E056595d1", "3"],
  // ["0x7c3B2e04f2C07b67dF7466071ec6017d86310279", "3"],
  // ["0x3b425b9d32E1D12bf02B70Ad14FF7164533B4208", "3"],
  // ["0x9D42b9Ae86d47Ee02900c5df8d090D27955FF8E3", "3"],
  // ["0xE1c19FEf10D62AAA1fbC3c8A4286e0A3E33aEdE9", "3"],
  // ["0xA3d02AD4bf5BD6F7e9B73930c498C4CA1eE40124", "3"],
  // ["0x2EAfe7265584E8C44c158B9bD5f78Aff8309684b", "3"],
  // ["0xF613cfD07af6D011fD671F98064214aB5B2942CF", "3"],
  // ["0x10347BEb6fa3b84c29d6A8Ea6811582AB959A3a0", "3"],
  // ["0x4EfeceA2A42E1E73737e4dda7234e999A84Ca60B", "3"],
  // ["0xFAf7e95E4C61E41705C03637c17cD08B43bFf338", "3"],
  // ["0xdF6e01a48C95D50628EC05bE54EC37315f82B56d", "3"],
  // ["0xd2F2d0eF2c873f124C351cD15359A83730cf8cd8", "3"],
  // ["0xec9BBd1Fa539dF97B7117c6E26bc0DA2968a157b", "3"],
  // ["0x286D534C378B265ff1EC5D865803FcF2A465eEa3", "3"],
  // ["0xb1A495A26963b83C8fE024c03CAe8A971dc02f2b", "3"],
  // ["0xE576C0f771BC3B4E5eabCCb425Ae533D9e00E953", "3"],
  // ["0x8F66c0c359B4546512BC8dca379B89Ac93008d97", "3"],
  // ["0x6dA0B57132563DD71ef000ecab9Cc51E3a0314E9", "3"],
  // ["0xe7d9acbcf32b86e7b0132fc89ce9b2037313f042", "3"],
  // ["0x6aF95408aBF96ce9723716e9fb2C226ACc914404", "3"],
  // ["0xcDE29ddea1BaA16b57491F1f75249E0D0D43Ad55", "3"],
  // ["0x8938E1b732E7525F0b5E00CfeaD6D1Fa9A8Aa291", "3"],
  // ["0x5bCC75FEd927D8bbEC9794f30712EE8fe2d5B2B3", "3"],
  // ["0xDD45036A1C6EfC601655cE2E06456248E05596C0", "3"],
  // ["0x237Df448B02E147b6dfEc72D49A46b1C1cef0048", "3"],
  // ["0x5dC3Ebef934AdE0b78D4280fC03A176B20C25CdB", "3"],
  // ["0xe3DC15aE22e29609494CF1c196fa90F5c5D320EA", "3"],
  // ["0x90D6b55d2EcE4577525BE20859F72548fae3C8c6", "3"],
  // ["0x7489C2174A8498E1eBC8246bE8001b67fa1E8248", "3"],
  // ["0x71aa9B8841b8caF5bFbD45b2BBc4eB8CC6bdcA01", "3"],
  // ["0xcb07b4402f00e8AdbE3d44dbB266dD0e03c1958b", "3"],
  // ["0x03D006bF57aa49C5f07bB9bEaa59F5c5Cf3e076b", "2"],
  // ["0xbf9Fda32692b25c6083cbe48399eF019B62F0712", "2"],
  // ["0x44337438ff5Ae8860d590551D855a9368dFd0083", "2"],
  // ["0x02D67A988653Ee16e46DefB7b693d0B18536A182", "2"],
  // ["0x6c09CB67023eDEd2efD8DB316B6eE95B9ba2520E", "2"],
  // ["0x0fB1812075EB3A215B1512B65b4482D2B250B01b", "2"],
  // ["0xd28c5cC6351E2091c16d3481Ff22dd005f2b2B9b", "2"],
  // ["0x7088D437C6ca4d3f89EDCF1740117aB5De385a38", "2"],
  // ["0x672C3b982325bE86E221fe5d0Eb5391ffA9CC8fC", "2"],
  // ["0x3913d13611D2F128e3e1d3Ee7dAbA53e4d37a2C8", "2"],
  // ["0x2349783bf3e10b36e78e6cb9d9f417c964ed1d82", "2"],
  // ["0xf8bFB84ABc622B238883BbaB3FA25a943a6c7C55", "2"],
  // ["0xb2e0f503E670180a42726BF1dB016CEe0c68e2Ea", "2"],
  // ["0x8cf667B90274465bfFA3D765446D5C804625713F", "2"],
  // ["0x141c663fD81914A3AD328bce16a1b817A7bD82F2", "2"],
  // ["0x20Ad79454538Ba7Eb0EEB1D292cC957701d5047E", "2"],
  // ["0x807d3e16C6bFf8816983346810FC3049377643eC", "2"],
  // ["0xA26F1dB3950C9F1ddEC1233408FB0F495640c70D", "2"],
  // ["0x182B32912D74A620124F7BdC13f6dA38c5DbE8CF", "2"],
  // ["0xE67f7FCad306Adbbee108290DE00Fa3310Eb35A0", "2"],
  // ["0x0DA9a09Cd083602B0E31b941e374d660c966670f", "2"],
  // ["0x63D9733c202e4b27c098a31B4D249961B79E2B46", "2"],
  // ["0xc8f2cabcdd860608a0B57d370D0A09ECc75b64c5", "2"],
  // ["0x7B74EC85D1887a2b8b713ac2040f749Fb0522D53", "2"],
  // ["0xb640efa7BAFC3C83526b6863764D7F09aFD9eb79", "2"],
  // ["0xEB859FD96894a219b31BE091480F1E9819ABad63", "2"],
  // ["0x37db1629458c7acd1ecc0b6702ac0c6636341f99", "2"],
  // ["0x2F23A04b829B1C143DbDBADe95ac37b7BeCe4c4A", "2"],
  // ["0xD2f679B2600bF3cEB0A56b760686d273CCa7C37E", "2"],
  // ["0xf543428D35aB7F3a86a7F4F448ec2B32eb0d8b32", "2"],
  // ["0xA5287D4FD7A2cC3735F4550a86Db5C235C674730", "2"],
  // ["0xb6f1DBD9a53a3de2FEA4314B870F3fBF1f103D68", "2"],
  // ["0x2f85305b9e9f11B544E2f951De0859C1057be06A", "2"],
  // ["0xd43679Aa528CaD94c6d101538033102c71C480A1", "2"],
  // ["0x1958DA56FbD6AE1D81c23abf86189b34f9178996", "2"],
  // ["0x8eCbAD4833FFe28125fF23C9ed80F4C4765246DC", "2"],
  // ["0x93A51Eee2306d98061747626776F8ddF761B496e", "2"],
  // ["0x06339ce7bbC9c3114934cfbcA8Ad91C878f0ceB6", "2"],
  // ["0x6e0440Dfaa41D1d47AFe1377cc9c33D37583AAC5", "2"],
  // ["0x8922639Fd2B3000864448Ff52c81d6d6F4B56FB5", "2"],
  // ["0xd448905EF32b90C2d5BEE343b5c56b13CCDd6873", "2"],
  // ["0x2E4DE8ec0395038065BFCeB2DF6794b0eF26a304", "2"],
  // ["0x71e9CA9e48Eb2d621535C274Dd21a985B73E0Dd4", "2"],
  // ["0x15e731512847C0d6dd8bA4Ca2765872eEA6aD349", "2"],
  // ["0x1c4F18969fB9dab92d2333407621FB56C7E5F271", "2"],
  // ["0xA207ceA3c3b0175e42d5Ba096658B1Ae14400E41", "2"],
  // ["0xeFb4da6E920d6616b703BCC9519529B45e500ab1", "2"],
  // ["0xC0E655600bAbAaBd4B921C02420309A04161D5d3", "2"],
  // ["0x9Ff1358D9E0d828B626BDC8DfA780c30550DF938", "2"],
  // ["0xFaf7908b8465E4F9509cbd5322ACE740FE9a1d6b", "2"],
  // ["0xc148E88d31c32225379b5caEb9D6669037768108", "2"],
  // ["0x287ea62A5eCA18385894fb086Ff3e24a0F37Bf9E", "2"],
  // ["0xA8C70533331be88189DF54E3fD30ADBD3072DD60", "2"],
  // ["0x9367cF8CbF3c172d7c2471edbBF0F3699BBdB9A1", "2"],
  // ["0x687F8127daa7EE41FB6b4e6437C920fc25971f53", "2"],
  // ["0x810d78229A935587A0B9aC71e534362407FC81b0", "2"],
  // ["0xa7B9fb9603CF08b6468A8be81b10680422432323", "2"],
  // ["0x59e4EbD1ae283cdfE4c72dDF7C0a92D219C08e64", "2"],
  // ["0x04d887B2D9D4F85Ba372F86E68fc4Eac862437Ad", "2"],
  // ["0xb84fF28b09cB747f52e163d785ec4DA61B21cc5f", "2"],
  // ["0xFF4Bae48a145c44429cA93b6D1bC8E655fcb6055", "2"],
  // ["0x8d0Db8d78035d39efE32F600Db5CB90DA89E29dc", "2"],
  // ["0xFb1369548E5b8768CA2C3aDA0556606bD7d8B615", "2"],
  // ["0x782A3D02dd042a7b3e9CB098619a959670775Af0", "2"],
  // ["0x30fc0b4FC17f3C751234Af979Cc0a2dDC0921412", "2"],
  // ["0x99BB9ce479faB954C58d9A57EEE35F0d6F2AA166", "2"],
  // ["0x91071550f052daec876a0DD22c85586ac81BbBDd", "2"],
  // ["0x53043216A1b929105a33C14cD2717e70B3C5468C", "2"],
  // ["0x9D9F83e77cad7a524830fb2Ee27b97C5E5CafF6d", "2"],
  // ["0x4dAB00393B4FF29BBcc2ED13c58649f371b24bf5", "2"],
  // ["0xa1E84210239baD5571171a8fe304A90E7Ffe5189", "2"],
  // ["0x6664bBd4748c7678766caf2D591FDB6eE2CB1547", "2"],
  // ["0x6aB7B619E11dD533a2b01c51D5D6Db37829b2706", "2"],
  // ["0x738C9f6618191dEb17078281469Ded0524072119", "2"],
  // ["0x7156fC1035D3a300f0ED308C0C0bF351b05c7787", "2"],
  // ["0x672EfD32B2289BacF96357199C818758706067Df", "2"],
  // ["0x417459E1c86f6e4cb23d7d2aB384e6311c4E554C", "2"],
  // ["0x642C7d19B7b2C28346B92e6C0ef4bdC85ad2d29e", "2"],
  // ["0x742D2144589e5c0e678D510A9fC127e7Ec53197D", "2"],
  // ["0xe02eEb49fD2f6eD86491850F99f0382527E370fd", "2"],
  // ["0x1D31C709201694DC6A07Cd884D7c121a52665BD8", "2"],
  // ["0xEe42a6794eC3C9Aa00c307AF10c161f9E3503BE0", "2"],
  // ["0x19D8da2674E8A025154153297ea3AB918DebF96d", "2"],
  // ["0xB7c2f29feF3cc2CCc530E0E47d0DDE98614FBcC1", "2"],
  // ["0x10c5D48B6b4B64d2f6c5e58dFf55AaFaBdE17709", "2"],
  // ["0x20e78880d72540c8211c0D30aE9E3576094E9e8C", "2"],
  // ["0x853D18353Ac666E87dB98c59550F2C7068f55cD7", "2"],
  // ["0x1d8C2d5Be478C829eAFAB4d6C967f1D7F8a6095d", "2"],
  // ["0x2A09B72007F55835100D919D286f8D97a592f485", "2"],
  // ["0x624cc774b7c67aac4a47e73465dcfd246b744695", "2"],
  // ["0xd1b5728ed72cc49E1dAB3B671e32eaE5D05A8568", "2"],
  // ["0xf906deD81f0479aCeE52F3Ce46Dbf28E44daB58E", "2"],
  // ["0xa1B073Eb8d132F5e1574A1141E4f7b20dD1dcD8B", "2"],
  // ["0x859902bc140e5d4ed3295c859d4cd4f616c7141f", "2"],
  // ["0x2E14D039Ea4842bd21E3f5d63574cA87FFf12854", "2"],
  // ["0xA0663d8255563Bf89143c65bBe6a91DeFE241F0A", "2"],
  // ["0xc8a1B01f5F8dD58282E6e2B53BC62D73253cBEcd", "2"],
  // ["0x6648bb995216E656ec31959fC7c5657023CDF269", "2"],
  // ["0xB1570eA88B8a491184549f64596A8582c1897f97", "2"],
  // ["0x21370350b470154f70aC893B81d2CFc6d3960e56", "2"],
  // ["0xD48090757d93cCbb72CaCa56390fa28B6E3A5efa", "2"],
  // ["0x48Ae1C6899C19870B2D3269f1c3dEc2a1239eFb3", "2"],
  // ["0x73e4A0e0F785278De16811089217207d37b78b71", "2"],
  // ["0x3BF2fE6BFB2c713fd82ca4b93f1Fbb507A389671", "2"],
  // ["0x357039FebBF491f11ffE0C6aA3302C7Fc956B1e3", "2"],
  // ["0x19BEb0992046e0741D97579d6D3fceF1dC3F27e5", "2"],
  // ["0x1d40Ff3C3f87fe93862d41ac3024d6335b587FDe", "2"],
  // ["0x547486014c096bF8eBd43d9B2090db6DAeEF2B23", "2"],
  // ["0xeFdfb22c09DAD726371167F6650e5c54038301a4", "2"],
  // ["0x056EDAB5Ac58ff2f92C9CeB49fE9866BA034EbB1", "2"],
  // ["0xbf20064C795362e7A87F6d21fe3C57Bd99e4a9A5", "2"],
  // ["0x844851B6b828125838Ad821CEf0179d05712229b", "2"],
  // ["0x9C0fA6BF2C480Dcd787E6816593A97af690eC962", "2"],
  // ["0xa78e0Ca0CEb2090B572B568B2122A277A78eBDb5", "2"],
  // ["0x1D947bf9ad3DA972D0CA367C41cD5153b9D9715c", "2"],
  // ["0x5eB6b8F085ED86389D70c27DfcE0F8a27ADa294c", "2"],
  // ["0x9B7B33058f3F7415D7014E067f8974B55BAbaedb", "2"],
  // ["0x9be3EBd8246Dcf239184B6594a244A5522FcBD92", "2"],
  // ["0x7B0F7eA479f2A4E031C7DA84C7a5876eC46f3600", "2"],
  // ["0xC29C1DCA7AD046Ff2C5C85170368ae3F142a5043", "2"],
  // ["0x1A8de45a81032780d80a6969559a47268351FC19", "2"],
  // ["0x29D520Fe46F42dB6abe034FEc7185B46B8D6FC2F", "2"],
  // ["0xe2502EB83f07244A5b5a5Fa878BdBE9c8DF07d93", "2"],
  // ["0x4009Da255D894917aF3C4263455133E9d9121cdE", "2"],
  // ["0xc2AfE893D416A5F8671a28B71bE1b24e0e374294", "2"],
  // ["0xB711cD446E4973cd2F3d82b6618273bc77264535", "2"],
  // ["0x51021BFa9313A2770De766eDbf39dC43c47c117e", "2"],
  // ["0x41e6732f0bbe183eaa772f75bcac2398d817b95f", "2"],
  // ["0x5bb3D5e0c3992354307f48D4c31F8abC933FABb9", "2"],
  // ["0xa5c08e76debbfcea4add1bf232e6728ad40b552d", "2"],
  // ["0x6Ce8D6B9D1a80e3189F24dae09077d01C1A9d875", "2"],
  // ["0xc2865c162B959E923ceB4F9a6D2153A07f92C873", "2"],
  // ["0xD4239c4528AfEd77ce902448db34225d3B48f5b0", "2"],
  // ["0x52aDbB5d31883569d34FC5D0B4DC492897621ac9", "2"],
  // ["0x96948CEd9989707D9Cb849ed430C95e57c10a610", "2"],
  // ["0xFe2E3E999bc785bb053c85688dA6DaA4a19Eb0f4", "2"],
  // ["0x3CeB33Aa7dD005f4b170115756dc8a079CBF20E2", "2"],
  // ["0x2D062F9f200Ed1d8B6C25c4443d32e22B71F81D4", "2"],
  // ["0x5C47851Cf89d8375f3D1c1a1C2A2e24C4b391748", "2"],
  // ["0x4CE389DC37360B7617a6CFee5ec69D9E2C85ded8", "2"],
  // ["0x5e4B49881b28Fa206945baB81Ced5303EF93075F", "2"],
  // ["0x048C0DA089C34D67d21CF2098947E2dD8412c01B", "2"],
  // ["0x76af7F6fd239994DF462488154bAb99dD46D38f5", "2"],
  // ["0xaAB2ca8330CF0903bB166fd44fea99bDf607436a", "2"],
  // ["0x0fB0fd84628aF54005B7BdcA204121ED72695D48", "2"],
  // ["0x25A03920F9Bea6a0905b3aFF5C58Cf7770184a48", "2"],
  // ["0xA1EAA80ce04Ef5536C46Aa7564995d596cA60Af4", "2"],
  // ["0x7a92846246457F64107ab470F34760a7117050F7", "2"],
  // ["0x6191551357987Cbd9809eA5c4411678cBaaCeda2", "2"],
  // ["0xcAb2b13d7D6Dcc348bd01598663EDC0DCb55FBd0", "2"],
  // ["0xeD2A7e8De8f10f098cA43667D7C7f8759028D29f", "2"],
  // ["0xC4518Ea8ada4190eE3DbFd14794b3b8Db4daaB71", "2"],
  // ["0xa44485865165D13D7f1db22a9Ca9440cfAc48f75", "2"],
  // ["0x0014DB7A74Bb1F508cdBd49b14b2dadC7C4B0313", "2"],
  // ["0x957F51Aa755Ca731904871ce7C7CB0510ff25aB4", "2"],
  // ["0x4f3aC27cF72Fa31ADFeAD6B7B8000382a5853F94", "2"],
  // ["0x54c861aF0c8De9FAe311cB74F646A6d1BDB41631", "2"],
  // ["0xfE6cfD45E46ec9152499a3Ac896a0293583C764B", "2"],
  // ["0xa94783af0f03CD8287B8027ca6D8D7c093cCCede", "2"],
  // ["0x6c804c79C344091aB413eBc9Af43d5916E435BEb", "2"],
  // ["0x62BaF655Fab209FC66413202d751E39B6b1Abf6C", "2"],
  // ["0x1bE2e5c277f77679888B11c9311680fe873d3a3b", "2"],
  // ["0x26AC07124A71d1da37C52afb850d04Ab12BDBaf9", "2"],
  // ["0x7E5cE60D3B9e12d8deDDb94c7b8cF1BF4D7a2bFA", "2"],
  // ["0x58bAaC0A5B3AC68ee9749D5eE22EbEE05bafacc1", "2"],
  // ["0x1AfC8C45493DFb8176D12a5C5A0469dC4c14f02a", "2"],
  // ["0xc333c52C34266d5a78577eCC1CaB27A774A37Ea2", "2"],
  // ["0x1f2dE14A5eC8ed3D77bBC158b33a585733303898", "2"],
  // ["0xB1cd30e2EeEE2674Ee81057C315E4542B0aB2996", "2"],
  // ["0x7e97e648B6576187f1A4a03b194CBFD4eE76F543", "2"],
  // ["0xa4032efD7C5480A3910aEafE0Fe80baB17778509", "2"],
  // ["0xAEaD2871C6c56DeADee2009FC9E7d4E89A18Eb06", "2"],
  // ["0xDB4CbA64314645D096bA7f0069d9C0c538c1B024", "2"],
  // ["0x19FC1b0697Ea155A96b0824bD5a32492E01987Ab", "2"],
  // ["0x105e7Ba0C4FFF4F3cAE0488319496D44b7C84A18", "2"],
  // ["0xD9bF95Bfd66D54E4B06854C01fC2eDe9e9496531", "2"],
  // ["0xa45A3692e37089cE1AFEc88921650Cd1f1C2c6bD", "2"],
  // ["0x08Dac2A440C6731ECFFe0a90871789aE4F09C0f1", "2"],
  // ["0x67a32e5b2E3923f6575269b4F77055dD370bC629", "2"],
  // ["0xf5A1Cf1A7656fd1C2a40c539C6a355050489C620", "2"],
  // ["0xFCa40c4541859818a12c60E043191C345323E98F", "2"],
  // ["0x6070e411A4C482aF2B28D40eeBB6c6D6899c6cfb", "2"],
  // ["0xD59742f90d770cf8d08568f793678aF2E94492eF", "2"],
  // ["0xe4af312722242acc96502c9FFCc6E7ff8e29cC9e", "2"],
  // ["0xB4983B3aB0903c85D546BEa30A6c3d45675442F4", "2"],
  // ["0x7642D24DCb4873F7BFEe5b45D42C04BD2b6243a3", "2"],
  // ["0x5dCF86e62aF643B9B4569E3D4144845a748eD9eE", "2"],
  // ["0xf080cB81190863811541c9e15aA37107A530F28f", "2"],
  // ["0x40196d371f7c687B4007Af056787c54f82c1Efdb", "2"],
  // ["0x4bB7Eceeb36395Deb86A42be19fC1440A23B5eA0", "2"],
  // ["0xA895792bF01FDd3a268e86F2d02B64B93159d518", "2"],
  // ["0x4790E91DD74E5690ddF3cDf0bf34773D2163A34F", "2"],
  // ["0x2cE9B35aE3ddB0ceEf52fB41022B1d38D701b25f", "2"],
  // ["0xB00cdD1Ef43e7B7d2Fdad4a07e6A1Fd13382b90a", "2"],
  // ["0x606a1cE9583Aed43b91A3aC765Efc966eA021A94", "2"],
  // ["0xd53Da12eAdbc3d1d357B1bD6bDa42Ed74B492f40", "2"],
  // ["0xBD452c93DEd5Fd0A99cF39Ac53C657FE708a7C76", "2"],
  // ["0x059138E312e7893E5f524aEaF93D98789B0BA9E7", "2"],
  // ["0x82c854504F266dAd4a7eA9E448Ca7B1572AebF1C", "2"],
  // ["0xDBd124A8a549534a3544886d2B8343086f396CF6", "2"],
  // ["0x8B0571BE754a2e8E73446a7DDCeA4728B949C9f3", "2"],
  // ["0x5D00389d935CeACBdF6994e6393cA716bbFd6819", "2"],
  // ["0x65A3DDfbF1A22D24854F35f4E4Cc59F70394f017", "2"],
  // ["0xe976cb353685b4ae0518e15959495255dfc83458", "2"],
  // ["0xB646bC0A6EDcc8C0c732411AA6a29B0383dEC742", "2"],
  // ["0xe4D26Ac24c1A825bDa13Ba612a790487F13aCf14", "2"],
  // ["0x99D4dd846869990c577b492Cfb0B26940d551304", "2"],
  // ["0x4e000d77a581983e3fe22f577591e6798682232b", "2"],
  // ["0x324396c5B417be9B0155FF87aB54c6770f2C9a86", "2"],
  // ["0x16b55F49eF142c08C69d263eB2e0eBC32D8213c0", "2"],
  // ["0xCE3f6A9323Add3D8151512eEf0C854686eb2182c", "2"],
  // ["0x09c9C2d06031EAD3269A582678CDB0EB4B628F15", "2"],
  // ["0xE80cc1600Bfc4994f42cdbcBFc783A560e40e81a", "2"],
  // ["0x4d250Bc3693a5CF0096F645d0BA1bc13bd856524", "2"],
  // ["0x2fBf312bEC813210187075C2b098D515137C06a9", "2"],
  // ["0x6A68b15a9eB12bb3951B5EbF2c321d4968aAAC51", "2"],
  // ["0x5395A6f42C32cb0060B54AB0F26A06d0C404c1D4", "2"],
  // ["0x75d4C4D239784F2C75729FC94F27417531Ac7Aa5", "2"],
  // ["0xB3b615Ab7916F12Ef7B1c889660c2C8A3b361AfE", "2"],
  // ["0xDeFA5675CDf2c7482AcEC6A16626D14941F0ACbE", "2"],
  // ["0x720Ff27ee0Cae603D54c915c2c2aAe9E467a3Ae8", "2"],
  // ["0xD060c64c2034572379D8906D948000660C8fd02f", "2"],
  // ["0x3563aAd10A601d8c810Bd79A8820d292f1998EED", "2"],
  // ["0xe197535eEFaE6FC554E0BDF2CD96625694F63449", "2"],
  // ["0x24588627043765178946391D7e440802586A22Ef", "2"],
  // ["0x7A166f2Fe92551d6058777367634aC0D471c9C80", "2"],
  // ["0x9810Bcca57ED452a8C47FEeE9aa340486084124b", "2"],
  // ["0x76a96f96255A7984dA77Cf36544F9206dF730fb2", "2"],
  // ["0xb8dE6B636d59624105D688C50Ce97Fb5aBb6B0D5", "2"],
  // ["0xC1263a10b5BF7670ad4410BCA84A865CF7b05054", "2"],
  // ["0xA59B9AB8e915842215Ea1dfDB401499338620a17", "2"],
  // ["0x7BB3f99d74d3d207C79bB0FA62c4e79Db3C53722", "2"],
  // ["0x2b94C14422a0e84EE75dE2F255d1478703d15797", "2"],
  // ["0x5ED248c1Cce1DdA40B6AC32C968E1d32d54914a7", "2"],
  // ["0x048b8006C7664a5f865698B0C664A71e6127890a", "2"],
  // ["0x4f5C885B5d835D586B6757F8200930d892f4054f", "2"],
  // ["0xa7C021A35De195ac1E1E653494230E8eB6b226Ea", "2"],
  // ["0xC5Ce736A1D6539DF71d57ae1F562f5Ac0CA948d2", "2"],
  // ["0xF408596d91E26c19346Da97a0fD6ABEa8112c264", "2"],
  // ["0x73a7CE7E5438A378A7218A326Cf54bceBF4F52Ee", "2"],
  // ["0x4008e000d9E590dcC98019D900c8d9C116108C69", "2"],
  // ["0xa2275DE35feaE9e52AbD9c9b78651A5CBf0D79F1", "2"],
  // ["0x816A8bc87ac6D3551b95EC5E27f4606eC9019A2a", "2"],
  // ["0x79EAD0993971c5A339e48FE7017d7AcCe443eA5c", "2"],
  // ["0x22c05672D1FF721d8D67C4694BCA70de839E3a58", "2"],
  // ["0xBeF9F2c98204C8f53d4f82e81514903ad44D6731", "2"],
  // ["0x5E4b62f4f20699e3A723237c543Bc0d28BaF6f2f", "2"],
  // ["0xd2816cCA9a7a55CA704AbFCDdF62619B0a1A5116", "2"],
  // ["0x782bE607d3d45e86DFC559d73cE43DCf0471eDC6", "2"],
  // ["0xE870e323fCf2F00E7a055b5E9FD66fC01e8D3B9A", "2"],
  // ["0xf092B98Ec6e40894154e07364EB3283Dd96b6790", "2"],
  // ["0xC408833AfBbf362200Bb3f0105C8964aE4936f3e", "2"],
  // ["0xaCdecb74D606A228BC489920842239f416868FB3", "2"],
  // ["0x99c24b90df184b0B31366558994094a6C3bec0A5", "2"],
  // ["0x0eA27D8D46E71ab5C15D1A63706652f9CFcF2b95", "2"],
  // ["0x08E7Eb07bcD4bE629bAb1B619116a49ea141Db98", "2"],
  // ["0x56C03f4e9Eb2B5764908fB2C42c90783E670Af3C", "2"],
  // ["0x573FAb6FB101729B5cc186083D0B8A6Eb1Df234a", "2"],
  // ["0xeb36f5cCb0B681b7a50f360Fb01595cfDCc43B79", "2"],
  // ["0xc3AABaA6331f170529Bdfd335bDeFbD1956AffFd", "2"],
  // ["0x7E7721e92e8216090157d57ae5cc4B9c02B63400", "2"],
  // ["0x1966DdCA83A3eA832B58b71c6FBF01c90477E30c", "2"],
  // ["0xEAce165d04126C6a12bd89A109C30fB64bE8AA2B", "2"],
  // ["0x2F3D48868f9026E39148b168A3E543aDf3CD6Dfe", "2"],
  // ["0x77dd6d53c978a460c2402e66789d3bb27f40a77e", "2"],
  // ["0xba3f49fEa8B65374De8fd5cFE4882D6565DC5A65", "2"],
  // ["0xe613402Db38a2c7EB25A20A80FE07F9876eaA7c8", "2"],
  // ["0xD7CF9bF128C51893ef94EE0EbE7e41f2972603cf", "2"],
  // ["0x8A0187866F6Ff8de8D9116E73aDDef092ed83969", "2"],
  // ["0x8a2bC157090dA1cA89B24CcEeF52e8864A4FAe20", "2"],
  // ["0xe3BA560000dce782be898e5B560171e1E0785a4E", "2"],
  // ["0xFE806C79275B249D87BaA5DAC4d21A594F240aD6", "2"],
  // ["0x801F15e1F76cBBFC6Fa7FcB8790531B051C42a72", "2"],
  // ["0xE749e35d911A641309c5FADc837D260F5CfDa08E", "2"],
  // ["0x6Cc82C9390652C401d2C6af8cc01601ca4d4790c", "2"],
  // ["0xF6edCc57669D49FFA346Be43345f5fE7a216e2a9", "2"],
  // ["0xDB1f6231351F2d25dD41637fBC8d7a1dA1601A0b", "2"],
  // ["0xbbaDd6F1F2034edb0DA04DB480736a67947FBCaA", "2"],
  // ["0x992d696d42E1fC6Ac1Ec64fEF0Def5Fd1316f177", "2"],
  // ["0xf4bDf7FEe399E8F9551c09eD7702E970bA938297", "2"],
  // ["0x068518345fcEA5E037581d49EbBAb6C4c75B7572", "2"],
  // ["0x7858d94c00AE403D1223A10dD7f676C705F9543D", "2"],
  // ["0x4Beb9282D6D04568A13Fe25777a3a8d7Ef35DcB1", "2"],
  // ["0xbb3383797c616e5e81619cD501c8DBC50ddcc89a", "2"],
  // ["0xcD8355A013c7f7D62D06053A3e7815F71D6f4c77", "2"],
  // ["0x367a1F56962d69189B9594ca5Bf96dcc67342Df8", "2"],
  // ["0x9055feaBCe5A1dCfC5748b99c460E3e57458B1d4", "2"],
  // ["0x49DD1856F13f703d68a72947E9D143366c15bB49", "2"],
  // ["0x71Aa6C4e87225fcAE2Df49f977C7FC0d1e8D2112", "2"],
  // ["0xf326aff3f250b8E8e83f60eA2C2e7aB7c0D71d43", "2"],
  // ["0x9B22A716e4C3d679BD545dE1C6A58401961364Ea", "2"],
  // ["0xB645443F5281C4110E6a61b2B080baca7C49018a", "2"],
  // ["0x91bd525eEA07fDcD4F528bf0c357A1d4ff44653D", "2"],
  // ["0x4306a9f32CE8c597b34Ee8D580DF06ceC369Cf33", "2"],
  // ["0xcC6B7C4BBBf72CdEcc4452E98c4e0700A4429Aa0", "2"],
  // ["0xC32b926f77002A23012c80daDF01C3382828Dc80", "2"],
  // ["0x5460267a7179771EF105Ae14B2633a459CdF0D03", "2"],
  // ["0xb746b4f0Bd2E86876433223b0dC2e0F1E24f311d", "2"],
  // ["0x4B6e05204917bB195f41A808cD85A98115997Dd8", "2"],
  // ["0xaB516EF734Cf9b0A976B22E79EEd7b48E4f9239B", "2"],
  // ["0x74E4222727143E162e43FE09F04ed0194DEAB829", "2"],
  // ["0x2c3BC01eF34C2D5dC25D8C65625de6522E3C7D27", "2"],
  // ["0x3D479A2B304A23672056D2b8DBB18efD49e5cd2b", "2"],
  // ["0xea2b30df089ee1cc8d9a525e8289c5c7e7e15417", "2"],
  // ["0x264c926aD4419B32Ead0042E442c6cb6FE93ea09", "2"],
  // ["0xeBAb2710CBD1C5Baf7e3F85977fF489f1c8CFb5e", "2"],
  // ["0x8A076108597f20be5821C68803127697071295DE", "2"],
  // ["0x6cbEff5e1f0fE1F4EECC7d756F9eb8757A3dc172", "2"],
  // ["0x040877dE61786163d59C4081F9F67F6F28F61f24", "2"],
  // ["0x31abFaCB674445f907bc67f97ad7Ac414B2C7164", "2"],
  // ["0xcc35Ca2A5d989BD0FBf3c6ef17c260672695E0E0", "2"],
  // ["0x5973B426c616aF28773d7A1457Bd645a220EA79a", "2"],
  // ["0xB548634Aa1AAfF9270E0c1863f972EDA75890445", "2"],
  // ["0xE9F9E6a10115C1cA4a90a1BA5d3235e6389CB216", "2"],
  // ["0x25DA48FcBD2d0430b4A4AB904807120aC3111567", "2"],
  // ["0xcA6E18bE9Db059424955AcA10Fe75dad1F27E59a", "2"],
  // ["0xabA27Ad5f9BFe6AFf84Ddd9B73bc6643Bf3c03e3", "2"],
  // ["0x6B044C4A026E869529D39E81330Cc0c28675E302", "2"],
  // ["0x542228617A0f8E93397B69A678b3e468c250Cd4a", "2"],
  // ["0x6D1912dc58B97D81Ad9babE58fb59a6C339Ba823", "2"],
  // ["0xB5BEebBFB568be3d5d7AFc7C35CAC5bC517a1fA4", "2"],
  // ["0xA0DBDa04ecf21656F05B4B43a90283e191D13BA0", "2"],
  // ["0x493D59757b68A2736DDad8078B5512F5ca4B7480", "2"],
  // ["0x7bf57aB5Cbdc3872050EbCD364519c02E80F814c", "2"],
  // ["0x2eBBa7a3916586B67543DE31eae1Afa554d070CB", "2"],
  // ["0x1AEF508290334025425aD283BC0C356aF97D9275", "2"],
  // ["0xB7bD3ef26fDEEE37A808b3B0Ae76b5e9e26450eE", "2"],
  // ["0xe61252912c463f9A6e083751948C918d37243b30", "2"],
  // ["0x19CbADEB83882Fab1C533700f2f2fC141260199D", "2"],
  // ["0xac2495256Ae3b9e7f809C61230F1De2044a91E9e", "2"],
  // ["0x7bF8139Bc5F009447D6d4a3B227899a195218913", "2"],
  // ["0x50A71F47522D27766894f0aBFdEf24fcAb97Ff51", "2"],
  // ["0x5d436809429ecf0A0754Da5ba91F9f4d0d062bFb", "2"],
  // ["0x46645a800dc67Aa6a4e42FA7f608360A587f82E7", "2"],
  // ["0x77a36f7ffb14b93c4d1ba0bb1d3aff3366636a5f", "2"],
  // ["0xB0202EaDc811c0194d41AC44cB2B1F0aF8011837", "2"],
  // ["0x254dd957E31CCDeab0B28Ca67550eF998B74C540", "2"],
  // ["0xa04082A4fc3A2D72138F034eC99F316aC5A90906", "2"],
  // ["0x148c3a0490cf722262aa4c786A3816801E3Fa632", "2"],
  // ["0x22EB670D5cb4AC0eD49f3AC98472e7c49BA918Ce", "2"],
  // ["0xeFa845164E612fe623ac21380AfC8ec78F22e3c3", "2"],
  // ["0xeCB9aD8D4FC49F28B799b40796F01977F7113E46", "2"],
  // ["0xF030b402dfDCfc88A306B5Bcb3E98602A8609F02", "2"],
  // ["0x1ba79387d759cC28620A3aef1fa9a8c46C347B97", "2"],
  // ["0xAdac245bE667C61c207a28b168093fDB4D4FB6cc", "2"],
  // ["0xf5843772820E0A63Dd0a1B01c3d69D6E83BC10de", "2"],
  // ["0x9309007e6C0bDd9c04dF47598D280a97E7f53546", "2"],
  // ["0x9b9dD8A8737b00946F4e35D73b3aDD447f604dba", "2"],
  // ["0xB6a0f7C0e0c559Ac37f6cB7FFEcC28E33BFadbDF", "2"],
  // ["0x5546498Ee22d1F3cBeCEF815176fB292598a67C1", "2"],
  // ["0x9d7EBC30aF74f5d5104aFEE01Eb3bd10D75A71E2", "2"],
  // ["0x41Aa7d375914ea1cB5A855642FC1924fd7e4Ea17", "2"],
  // ["0xD5B46091a8Bee2980e069647f1c5DE781b573986", "2"],
  // ["0x0A724787E762C12351ad675F614F3e0dF2BAAe8D", "2"],
  // ["0x1D836fE7A2cD3A1d6eCAd12c6D92A05e375772be", "2"],
  // ["0x10224c19e7Ce6464F68E1FD317Ab2b7bfb119EA2", "2"],
  // ["0x1eDe28b78F7b823166de88A5fcfda7DE18343f0e", "2"],
  // ["0x9d121161081B3c76804C3B9E934797D4dE2B4Fb8", "2"],
  // ["0xF397DC1bb4f02eD95865cAFe2Ee2010D74af5226", "2"],
  // ["0x36314C120E5BeAAbC04ba4409384AE26B79eF370", "2"],
  // ["0xe8cC75e027A02b14e65e6fB4135587140DD0d9e4", "2"],
  // ["0xe2A44df1496dBA86165b12D8E737E9B5bcc20D3e", "2"],
  // ["0x210485e6b00b88EeD376566beCc6D827A58643e4", "2"],
  // ["0xa83fC4484cc06082c9eB76a0B007053B6d016A96", "2"],
  // ["0x90fD4033564eF7791d0039CD7CD754725C759249", "2"],
  // ["0x9C6F20AD5111F1f3B6F2d46Acc695A91976B5905", "2"],
  // ["0x33404B2fac3649b576654c67efA98228d051e63c", "2"],
  // ["0x00bE076edB32ba55303B51d111BBfB43eBEf9C18", "2"],
  // ["0x9F06d7e9cEfd3092d18736d41Dc6DdA1673A9645", "2"],
  // ["0x1b842A4818A9270A6ADe9765FEA01C2c36bADbB6", "2"],
  // ["0x62705362BF8407fbBaD2303a4dd44A721fB5e559", "2"],
  // ["0x9E7cC8dcBF954de674F310D1305D5c5793fa748D", "2"],
  // ["0xa55705954f4b629aB7c2c618fA325D2b95D1E9f7", "2"],
  // ["0x34427072De07292302a80ed3d26994ee79ABa9A8", "2"],
  // ["0x9745c76880b3a173c470a003bF219Bf1197432E3", "2"],
  // ["0x6D4b80ACCA7AbCd6d7241cB4CbE57aeC0CEdA931", "2"],
  // ["0xA429f7bEa7e847aD5e9eEaB9eEFe966A6Fa1EB89", "2"],
  // ["0x5383Fc41ED11A8e35e2c9F92dF8928e8D71363ED", "2"],
  // ["0x46Fb82397916be43EC1A8d693BaaB26C97F124EA", "2"],
  // ["0x75A3dFC297B7C15314833f71Ebc93F78C3f3d7b6", "2"],
  // ["0x8D56759265d1E37e9a9Dc03d35C94fA57bAa7b67", "2"],
  // ["0x3d6AfE2A180728BD3C073B6c410156Cb3a9230DC", "2"],
  // ["0x275E60123C050206a6AC693f8A78c770eE6Ef023", "2"],
  // ["0x1084Fd00bfA45C7Fd7C3Af4d8C9336924eBE6259", "2"],
  // ["0x9Ec93Afea63d6C9a33Bd6779516F72368a1B0C62", "2"],
  // ["0x7f1a6d1c6d065b1F4C8ab0279c6D665b8DBF3995", "2"],
  // ["0x078123dcd2f8cc1f4f0dfab87ba725319581846c", "2"],
  // ["0xb061428D604b1b36cb75c807B6fC71DDfc4d4Ae0", "2"],
  // ["0xCcE9BbB6cf5Eee098F427fD6627f6c6211e75C6E", "2"],
  // ["0x324B2B67FB5F0E72b8ecD72E2128F627fC75C9BA", "2"],
  // ["0x384b7830FbA2AC8263773643eDa7B5ce22362EAE", "2"],
  // ["0x7809Cb396D8d9681757f0488176D59dD19079bB3", "2"],
  // ["0xBA629e2dBdfC5Ec1fcD0cC9553744e1601dB0907", "2"],
  // ["0x8FD7234dE5FaA72B7539d20241BBc456493F924d", "2"],
  // ["0x8dc887F6846A3447474A7C92DcF03b079b1CbbFa", "2"],
  // ["0x0a90b83884870046b73441af03b76c35c1d21763", "2"],
  // ["0x04e7C7B855faBf2AC21f6e0F7452A0a0ed77301c", "2"],
  // ["0x741f2B2D0Da1e80203Cd43Ce18e839e87C4A29Af", "2"],
  // ["0x37cF3381F428984B0eb2F33c7C020B679Feaca09", "2"],
  // ["0x825e987bdA305E260F84191262E0D5C679087249", "2"],
  // ["0xeFb12FFEd139D0812ffD71d16685EeF00c8f5125", "2"],
  // ["0xB347A548597d7797Dd468280f6A1c89741c8f84B", "2"],
  // ["0x2a4D376e968390950F883dc1CC4AB2e406466FaA", "2"],
  // ["0x5C62830CBe0A79DC4d3e389e88d2D866339506F4", "2"],
  // ["0x9D2e823d8854802e3CAC6162f3aDa71AC30C8673", "2"],
  // ["0x4ec415A09eB185941DED64774F79f6339B4ED0b3", "2"],
  // ["0xC54AA901b257d4c47C44217f538c84Da28b21308", "2"],
  // ["0xf20C8002A384CFb0eC91971FFaEeD521d0cd241b", "2"],
  // ["0x59947dfd334c05d39aA4249E1478789e89a23e88", "2"],
  // ["0x3e8137e5856bfdde06995871a77d485fe691451d", "2"],
  // ["0x606a90DF26ED6b2680aF64fc63E2887a726703d4", "2"],
  // ["0x24b05De6a55ca98B0935cbb660FFABB9FA9E6A50", "2"],
  // ["0x8Bfe6CeA4c1C5725Ed39215AaDaBE6243561badf", "2"],
  // ["0xd54c1841e64E84c9C63FcF6C60763f0C751C3Aad", "2"],
  // ["0xf8885b3DCB38bb1A84761686AA4d2403AFC6D227", "2"],
  // ["0x609d304d781A2ec2E098957bF65f7B8D627c9ece", "2"],
  // ["0x48ae46614fbb34fe5676ac6a420d5ce34e6c65bd", "2"],
  // ["0x05F122D0df4De17a9bBfa7f15448e972F587E041", "2"],
  // ["0x8293fCA53e855443408810e4a31f49D5C7c410AF", "2"],
  // ["0xEb546f8DECE2463b7EE9c5A09BF2F741ec705daA", "2"],
  // ["0xc0eBE72C83646F39197F12935b64d2E581846a26", "2"],
  // ["0xE999d4F305bc7bE77A678945206604Ad37bB5991", "2"],
  // ["0x2B78CA611c0a94E4812aF03Af9a024235660B513", "2"],
  // ["0xbB88d430f0b48EEdA02312e3e495A6cfD0593735", "2"],
  // ["0x89e1A971D1f62Df8CcDaCFb11954D8f1c81897eD", "2"],
  // ["0x7f05d5f291922ac1fcf5ad07f419791853e34da9", "2"],
  // ["0x869875140575F65511391Fe00105ffFabFE37A11", "2"],
  // ["0xB340d9F239D101d8791ebe3ADd34675EBc184941", "2"],
  // ["0x81A39aa0b0D6FA8513aa79929E7C01DfaA232Fce", "2"],
  // ["0x6ddD2bb95E9C313B49273789EB3F48F450FF1ba4", "2"],
  // ["0x7d388c8b67255f66568d21425fc270210B2C6B44", "2"],
  // ["0x61a002c7F723B8702Dc910D14e1d95a523a8FCCC", "2"],
  // ["0xC9D2F9c0F8A303DFa8141011b9d04dAC08d10D89", "2"],
  // ["0x6eb35AcFe484573CaC7A46dfA156c81eA9e2F8E6", "2"],
  // ["0x793E9bEa645C2C5e3435485C3CdC434830795FB4", "2"],
  // ["0x31879cd3d9a2Fe7A8d92dC42BA9c5F035FA66aF6", "2"],
  // ["0xe55c393D7d95F0A55801f2ff1DFcc6F95B339CCC", "2"],
  // ["0xF5Da8b883eF7A7a195E52862D30B2ab2D50DB675", "2"],
  // ["0xebFBF609A4EBd30414b3cC2Ec460d365FA1820C3", "2"],
  // ["0x61e51698560254718C29f2Fd9667c13927F2d467", "2"],
  // ["0xa909D7257d9CeEc99378a46006EEeF1E90b04B1c", "2"],
  // ["0x9DC65FE9de1fB819f6ccc4202aBa41726d2a7C69", "2"],
  // ["0xBDFa82B7f0189Ef537da76a46e6A47603377E2Ba", "2"],
  // ["0x038bBA42451025FdB3Ec6c4789CdB6491D242e9D", "2"],
  // ["0x0244f319301A84dcAA2Bb639ba913d5790483B50", "2"],
  // ["0xeE033638d44be47dc01495493985a7FD65Ce28Ee", "2"],
  // ["0xC0EFbE2c3a42E0f386FC4072ef6a6A56D66c4b0B", "2"],
  // ["0xbfF45BD64A3FF60073226CCd872Bbc7A0949Ea18", "2"],
  // ["0x45d4dB73B68047DC774Be0f13C3ba3011A0452A9", "2"],
  // ["0x46949F1DB2AC22aeD2ADE9702c9Ce73ebFeBF8A4", "2"],
  // ["0x81369c3a06C35D15CeB63CEb2c9674aDa7379be1", "2"],
  // ["0xE5A21500732031E7678575b280c486E1C30fb478", "2"],
  // ["0x7DBb239CfDAc2591b54B06592039010C29AA0b59", "2"],
  // ["0xF7D93E1B05E8a7406ecD0FB51202E5A4fb039783", "2"],
  // ["0x12a0dd19D3f878697c7A1F804AaA1dA84229566C", "2"],
  // ["0x89621ed6F406f3C36a26e4f914662ede41b14f8d", "2"],
  // ["0x1dc8a1653ecdd65771112ed6a88854eff47b6bec", "2"],
  // ["0x93DD030919d7ad900A13C1D937b43FE3A9d9C726", "2"],
  // ["0x81E4e88E9033b770A763E5b4d8B733B6EEEf686B", "2"],
  // ["0xE2139E7F2b5D3cB023DF2aEe9eF5BB96c64136cE", "2"],
  // ["0xE3Bb65b1595BAc68dabb24ba32D1BFf510091eD1", "2"],
  // ["0xeBe1224EB66105369BC4daCC42FA30b244DBC6d3", "2"],
  // ["0xa400E4B45e5aaf09CCd220199cc6E76B2F5DAE0E", "2"],
  // ["0x84c53eB896F8469e99E42044FedEa7190Cb9e11c", "2"],
  // ["0x273974ccC85F0A650E1B5099868E6Fd63889BAC6", "2"],
  // ["0xd9Bfe4CC933231ba301b101E0F2bB8923B115ADD", "2"],
  // ["0xE21413BC0d8CC4c757745A4AeF7Ef74a8805B3E8", "2"],
  // ["0x42A9FBDA0E608F76383426355d692ca465FD9750", "2"],
  // ["0xb6cFdEDbEA256B7d784A29ED35bf5F1c43d3F3D3", "2"],
  // ["0xdC30581AcbEda78dEC4b1b36f7101020cDffABD1", "2"],
  // ["0xC1923cAe3b5ff75c87A1CEfA8E80e2985E1232A8", "2"],
  // ["0x458D4Ead83fe53214ab40BD2584935706D2E756d", "2"],
  // ["0x525646bA927c1D822a44Dc19Dee88e67cEe6931f", "2"],
  // ["0x8C42F1c16A4f9db0ea128EFe276c1010611d10e3", "2"],
  // ["0x819E90207994C33f41540F42C9511C7208ad67b9", "2"],
  // ["0xC44521Af3ade453bc9F191983Fbd39E3dd6bFE3b", "2"],
  // ["0x96acC5E193b96Ba7F2798a79D71cB25eBe71adae", "2"],
  // ["0x873Ee3Bd3E6Bd102eE1c0902B767cc940121A9DF", "2"],
  // ["0xAB1C8D3b504e8D096Df60ab5138e5b8f86616093", "2"],
  // ["0x4F2C2dB0A694fEE6a07521f4E0a431778A86FBc6", "2"],
  // ["0x338bfaE80789A09a63831E61605Ae260862FF2C8", "2"],
  // ["0x68AA1d320d48827b91e2317844197F03d8C37F66", "2"],
  // ["0x45BEC6446dF4434c29f7F3F40cd84b77DCc4a6A7", "2"],
  // ["0x0231EbDB3DC5Fd302c3b7b8e4D9056ba32bE79f3", "2"],
  // ["0xD036dc3b29e55C0bdD75a29a17E1b3662904ebd4", "2"],
  // ["0x20d66886333375814919235674E68C7df7504659", "2"],
  // ["0x36c312b33F7B001b946DB14349B864AD60843d0D", "2"],
  // ["0xa85FA1B4b064fbE4f111DA849D1288418F92b9A8", "2"],
  // ["0x9b523f7d73676b892f5e3d632ABc0c98d285D00b", "2"],
  // ["0xf970a99Fc7Db861E7B6BCE1F1f9ba1Eb7c1327B1", "2"],
  // ["0xcE1CA668249E9B144a63eF60395e662e5724c079", "2"],
  // ["0xca01BaE7894e9d5026Bc06fF3E4ba2097d56BA59", "2"],
  // ["0x261483C76F183704524E1eC3a3C0A0046a8e6cE7", "2"],
  // ["0x3030E01dd91c5e7275F8596A9C468ab1B93CAb64", "2"],
  // ["0x7DB66b8DB049e5F75449596635291243cc3bAedE", "2"],
  // ["0x6829B3Be1C0c14b292549e1f2d1224764C1bDD4E", "2"],
  // ["0xF10FF6c03c5f951C9A4d02cb0Dca51BA442B095E", "2"],
  // ["0x4481157FBE9556D57744Bb87BBAE38628cd5A782", "2"],
  // ["0xE440B9d02Ae99C1Bfe68027CE3BDCBdD1E298aCA", "2"],
  // ["0xB9b7eaC9851D0912A96912c9bFA6Cd599908C17c", "2"],
  // ["0x131262c74aeA6457FF99424bc266fcC81A149a2b", "2"],
  // ["0xe701A35273c57791009a5d0B2de9B9b8c1fCeAEA", "2"],
  // ["0x9c203C00d702d0762dA5222Dfb937bedfbe3E00A", "2"],
  // ["0x7dbeF02a8D6E8C618872d02533229641DAaC60A8", "2"],
  // ["0xc942237914981fad5815F79F8f1c1292b98913E7", "2"],
  // ["0xF237656e841Ac8cb2Ce03D7a2cD1f1AE975Ac2b4", "2"],
  // ["0x401f0A1641Dad4A93Ee3169caBA50CBaD4471820", "2"],
  // ["0x9D989B692c75Bb3FCD6d2b9429DFAe9D982Ff860", "2"],
  // ["0xdC1a61419d77b801ed878C2AdE322F0e85b910fF", "2"],
  // ["0x4900d2e018F40d1D1aa63d2356b2130A4E10952e", "2"],
  // ["0x470dc8382B354DABA75325b685F97128ddbc20b5", "2"],
  // ["0xF79A145aeDBb3F541404257019c3fb84B1a1d03F", "2"],
  // ["0xA9d2BACab537C44cF893ecE13b8b73D340A07F8a", "2"],
  // ["0x598940fEbAE1b33D863eeF9b85384eEC5fe0eeb1", "2"],
  // ["0x92373997819B0E4529BE7988EEa4BedD4d276f74", "2"],
  // ["0x44938e22CDFc90e5Ab5e272E57217f42c19181C0", "2"],
  // ["0xEaCE06c09a26AA0B44FDD6Bcc730DB08E4711210", "2"],
  // ["0xbAE0dF94daC376Ee2cf759d7FaA4C916aEBee912", "2"],
  // ["0x8209A0f91fE84e756A624F079e51E9E29fDC252a", "2"],
  // ["0x1bAfF971123e07c2312004a6bD519Fa33085450D", "2"],
  // ["0xA354D7BF4d121260C4b252fe61f64Eddf3F8CaE7", "2"],
  // ["0x34D875911D2E4c82801a701072c63be973ef03f3", "2"],
  // ["0xFd2f4B9629a7DA3f17A05b0776C44d467faf415B", "2"],
  // ["0x73B95277F1C303daA1606Dc2e563820d2cD0f03f", "2"],
  // ["0x789C84C5C63BC248377D30676dF839995F346e28", "2"],
  // ["0x14776ba6f3867A187FD42183d88A32734cC4dfBa", "2"],
  // ["0x58cDc77A785A9E6b9A55Cd33D0Ee8817180419f8", "2"],
  // ["0x72cB4f4D34208B27a6c05a5D220f8A5BDe364B13", "2"],
  // ["0x48D433FD2B2D3B34F7c4a8484e905Ee3C8D19101", "2"],
  // ["0xec8607A0b3A99C92E546973bF1b95A026Ea246b5", "2"],
  // ["0x5Ee42F7983Bb88112FF2639095B31a53ED1717b9", "2"],
  // ["0xc1d423aE49fba66AA713610811d13e0BECf213c6", "2"],
  // ["0xc6a73fC975D1557D0149fD104D0ea38D705120B7", "2"],
  // ["0x1156a767b4De8af9F77Adc8F30313bbe7946B14D", "2"],
  // ["0x76C9BC5E6bD97337aD812c7AA7ae853f7d64B873", "2"],
  // ["0xD8c9a6ee7947387fa42dBA197f89C63eD88594cf", "2"],
  // ["0xE769923E53f50Bc2a4FAe8cCe134b8913aEa9F85", "2"],
  // ["0x87ffc335a97471F969B4D263Ba9C20FbEE3db03a", "2"],
  // ["0xf2b5475F93Bf324a22B80Ae8CAED7D253a2B4946", "2"],
  // ["0x5a84ff45A6400dD3c203317Bb1a2Ac6CE78C4D9F", "2"],
  // ["0x2b3a7d2b25Cc976F299080cD6A56eb3DB84C55b6", "2"],
  // ["0xA103Fb1C5E966758a6fda8072B189920309Dc60d", "2"],
  // ["0x988c1C81B477908b8D80F735c68eD276391741D3", "2"],
  // ["0x74e8D326d609f5632Cec23BD68434CDc125DCEE4", "2"],
  // ["0x5612a44E4a42d7c7caDB12abDcD4Fa31CeCB5D3D", "2"],
  // ["0x329939dF5a6e93dd8a47e245819aBA1c940c6010", "2"],
  // ["0xa40a36bA48A27053035F7cCd8A346C30b76fE234", "2"],
  // ["0xA22762bfCf09Fd487f4C9Bd0169803cfB6626e07", "2"],
  // ["0x6acbd57b033ff316c4c93502d39e9469698e6b73", "2"],
  // ["0x37015C3F9e6d889B4fBb6100c9fBcAD1a6601eD4", "2"],
  // ["0x1A52bD34A5e7eA6d42E7bDc99d81e0aee9354203", "2"],
  // ["0xB9ab088C02227E3c43805B3797cC140e89bECa46", "2"],
  // ["0xC7C6C02d917a3e97cefd695CeB6676C07db1748F", "2"],
  // ["0x6192d34554766F80f8Bceb24951224DA7dCeCAbd", "2"],
  // ["0xFe023aD19ca6c57681fc13fb7E8234B91a00311e", "2"],
  // ["0x3b27d389382ac7eFC86721c7E8523E4f24fCC1CB", "2"],
  // ["0x686605bC5f5eb68478d5798Cf0e0e19Ee50A0fD5", "2"],
  // ["0x3a6DD87c3d3a89D02B13d6714505A791320bb06a", "2"],
  // ["0xcfeaA6F48Fe7AB2626510B9e8547F3aF6FdF3b1B", "2"],
  // ["0x0AEa8e067b44C6448cB9b67bD227c9729569c1a8", "2"],
  // ["0x0907Bb13fefC50e25B0bFBB7C1Af9C2e02dbDCE7", "2"],
  // ["0xE3d228A931fd4838D230B3169872B423651Bb7B3", "2"],
  // ["0x736e0a7Be8c4b8E96e9E300974F68D5ff5C86911", "2"],
  // ["0x8dE76cB267aa7ddc16A12567c890F32aE3EF1F61", "2"],
  // ["0x5B972c36a36F28B526D2c40E0937434D84ca7E91", "2"],
  // ["0x754A2B1596133818519148Fd8ae74adff0628f11", "2"],
  // ["0x95c92eaee6a72e2884335f8bb37fd1bbb8e31145", "2"],
  // ["0xfD24Cbd0eB2cE7E19ef81f1085aFFa8A6D7Aefe6", "2"],
  // ["0x252375fcCF631CCF9012a7c60acd6D6D37c4a02C", "2"],
  // ["0x32ac1F62A838aFfa4AA0a6F2ac1fA20b63ae55c9", "2"],
  // ["0x14f13489d3D9226804c11469670Adbe3836986D1", "2"],
  // ["0x118873E63406272259F5b6A107a6810d99a2cd59", "2"],
  // ["0x639c1f7b5449536f57b2f8c923f3f9f88fd40331", "2"],
  // ["0x7E94542F798B80393BBd33D2d36d9024F9f470C4", "2"],
  // ["0x8A53b410031c6C606CD495DFE1F3e65003dC384C", "2"],
  // ["0xfb5Dd24f59e097C73Ed080807D12a1AAbAf33b0A", "2"],
  // ["0xE779ba66f6D856087AAe96050AD742E0e92bD2Dd", "2"],
  // ["0x95BbC00a238dBb99F83ca1107deB1A3575dE2924", "2"],
  // ["0x471fd7aec22263a1dc5b4981573176ad9a7380c7", "2"],
  // ["0x68fE68DEf5b7606DfD97dB7c1330c723c36236f2", "2"],
  // ["0xf55738474663d40AB788DaF8eAD11afC8BB20c19", "2"],
  // ["0xc3B4B9D5e0f633BFbf890Ef182a10B119F16b7a6", "2"],
  // ["0x156f3116488ed4681C748C3eeEca4913FAfe4b82", "2"],
  // ["0x1166da0451bEC6939A443937cb361Bcb12227f49", "2"],
  // ["0xe8E167Bca2eE32D5C83751d2ed7927b041D208Cd", "2"],
  // ["0xAD2ef2A3f81Dc630BE1d3C0ce23E80d94deC9Fa0", "2"],
  // ["0x4b49b5Af2A3591082e3513453719d9f0aa5d6C11", "2"],
  // ["0x9e64ED74775465cF4cAd1e78392131528E9aDD58", "2"],
  // ["0x261C97f485b32851FBA05F8461Aa3705188D3cd7", "2"],
  // ["0x65C35542dB19e4978544655D416beD5cbCd5F500", "2"],
  // ["0xeeF0CDEF1DBb8c26cBfB0FD8024d85794c16986E", "2"],
  // ["0xefee4fa8a355911f96763005efb1cf2a90c8e17c", "2"],
  // ["0x85cBe3EF683c11c7f6a95c235976120CdF1d0B5a", "2"],
  // ["0x88B20748727541EDDaB55dF878A7bc6e8335431b", "2"],
  // ["0x446B0AaDc8aEba1c9C7649007e94eDbdba3F18b1", "2"],
  // ["0x2e7c70f54B54787dcB47FcdE9C0ec29e35790DeD", "2"],
  // ["0xec27e34B4edFe4FC00f384ad8E59BE4251Af4B65", "2"],
  // ["0xC03B8cb52f8E776a21D40A78FfA11B5BC6E1d205", "2"],
  // ["0x07a8991722a09F7c8869E03A7fA1f5477a797511", "2"],
  // ["0x75720F62a923942adAA36037B57E3a3d38Ccb7FB", "2"],
  // ["0x59a6F585817e8587FB1360Aa1A2475fF599304ba", "2"],
  // ["0x5D5Cc8fab6003CC912Db719B628293860aD06cD3", "2"],
  // ["0x53D1993D560e7030D71BE707323714bf4Ef317bB", "2"],
  // ["0x385fd77f7B5A1e67222c94304D342ff4752ce92c", "2"],
  // ["0x1a9f25C22b890Aafce09f50e7dFd35EBb24F329f", "2"],
  // ["0x09Bfa99BEcCBE7f815480219726Cd8e96b8a8F76", "2"],
  // ["0x2E8c0dfead57F10eE350ddD5cB2237Ff6BCab217", "2"],
  // ["0x8d7C996455de4335ceB54c162Ea2a6f24294001E", "2"],
  // ["0x56879cc88fa3895C082C22035dB1386DcAc53bba", "2"],
  // ["0x036863A5A05c5A7EbD2553824Cb040aAa2a6D687", "2"],
  // ["0x6493aFf97BC67D70CED145Bc51386e68AEb6C4EB", "2"],
  // ["0xD4b15f8cB5848de16E8c42eC2a7DbC33E2910874", "2"],
  // ["0x0b2d536df0426fc6d8f5a0086f00029b1a28cd42", "2"],
  // ["0x2d19d78B7172464F295c200B18225f566899f2E6", "2"],
  // ["0xC456d38AfB6A48C15E7eEAac9b32c451846808A3", "2"],
  // ["0xC5D4625d741B4dAd947Ade0374E9876Ae8a4E5E7", "2"],
  // ["0x93cD80C344239Bc9c627DCa363fFCAE9b2AA1C3d", "2"],
  // ["0x5C4FE960950BA0E09A72869C3D51fe70f07580e0", "2"],
  // ["0x491dC270Cb642E3aecF4518f8aaC9656D6b40547", "2"],
  // ["0xF1c43051f63147039669A7e4b19D07107418D30D", "2"],
  // ["0x7B41F235e12ae2d3696CaaE3F921412D7c2FCA2B", "2"],
  // ["0x655d49aEB3bEB648Ab4EC15a9c5719Fa918A5Dd2", "2"],
  // ["0x97760A184FC572469d666d47F5432c12bE08d9Ce", "2"],
  // ["0xc42b005843b10471ff840627f825e1faae28e139", "2"],
  // ["0x289526De2B974Ca307B7D383D2C091E7Cf03c92A", "2"],
  // ["0x85f5De9d8C4B0450F4979DD9406C73E29680fe21", "2"],
  // ["0xB8c16EB4035e152B31429EC6f41431DF9b1f7C4C", "2"],
  // ["0x69f16a87a5217592262dc66b8B4F6bB436361B8E", "2"],
  // ["0xFe70f40e342DdaF75724Bc9FDEf47AD1B7Ebc1A2", "2"],
  // ["0x09a043874694C0576C66b2a37522107E2e129B52", "2"],
  // ["0xdF8c331cBc7Ee308bfcD6d2DF034b81bfee5D454", "2"],
  // ["0x8972dD4b7c5DCc2d541E0435d4D4C0bD04c837F0", "2"],
  // ["0x4bd39D06ADb265B8b6cA11314e332A85fB344827", "2"],
  // ["0xF28d9a8Cc1829083BB862fFeaDd68D6EFE26AA95", "2"],
  // ["0x74c06c8f6572222c4b14e37Ed248E1e856fb1B44", "2"],
  // ["0x3bf89ff9737919f117e94E8ff3521BAc8265CF70", "2"],
  // ["0x38870c865Af2cb070808B189cAd80E87EEA3BdEf", "2"],
  // ["0xdC6cAb0D3Ccfa21B707411de96f5E2188f79DD21", "2"],
  // ["0xD84a37d9659AE8A9FfcD5f2f0Aad2414FE7A6C8D", "2"],
  // ["0x717600e313a405e467b4ceb331a279a7f4cd7ddd", "2"],
  // ["0xd1353C3ae276eE4264Fb7a3932CcdCf5C398fC49", "2"],
  // ["0xa32dd46f770dcbba2346477a9fc552a805b5a342", "2"],
  // ["0x264C683aadAc9f6629B960C9C92f24Ad3F9Afd34", "2"],
  // ["0x1877FA3AF4A6Cec0C05f0932f87a0c386Cbf906B", "2"],
  // ["0x5E813Ae6338dF285811BD3CDBe39A3D4C1509dF6", "2"],
  // ["0x1C9Ca50271e2ad6123c7d53845de6E2A80a7cfcc", "2"],
  // ["0x65A20e69246D47dC201590222A15F459ad886409", "2"],
  // ["0xD1D21D4CA3d0b3f5d4FB8f98FaBc7D70DAFAD5C9", "2"],
  // ["0xC32B000942AcA44A05ccF9E3DfEec48Fb3FcD0b5", "2"],
  // ["0x92392604785c5679b637BAEe933AA1FFBd95931c", "2"],
  // ["0x302bb37570861d1E519c4d383d988Cb6BDFD6a1A", "2"],
  // ["0x7A91ee9A535bEd9Ba1bB3722C54D7918018Fa8Ab", "2"],
  // ["0x120f6EBdE2B8582C569903AC76A8F150bBd0a6BD", "2"],
  // ["0x921Ae88E3618189CA7d832D740dF2444FD0Df4c1", "2"],
  // ["0x3e716A0E4dC07999Bf688d43c05dD85CB445168d", "2"],
  // ["0xa15B81D3a6eC966cb2b24dCB268B554d61cCd7e3", "2"],
  // ["0x3d15037B215732EBd53CD437D8a00568124EA667", "2"],
  // ["0x7127FbBE0D54AdB13dee5505595483a55e76Ac25", "2"],
  // ["0xE5D14cb5775eb034156892C4B2685236901228c2", "2"],
  // ["0xAD889ba5A5c60FdBB87b25c6477E14A578e1F681", "2"],
  // ["0xFd95709C0bCBf738Bc96b0fCd088efc22A1c5A15", "2"],
  // ["0xd26d4157d590e324dcc7f6afbdff6fe716e09d58", "2"],
  // ["0x5d00389d935ceacbdf6994e6393ca716bbfd6819", "2"],
  // ["0x14a86ca4c238cfa02a7a0e22df818b00a3b80775", "2"],
  // ["0x3e55537b65527701dD6B101759FBbd4B33552808", "2"],
  // ["0xd814a03450670534f57ded478b25f6007a6acfbc", "2"],
  // ["0x25b071ca2c547851684cc02d95234266ecc373ef", "2"],
  // ["0x048c0da089c34d67d21cf2098947e2dd8412c01b", "2"],
  // ["0xe4D4FcBCec3511608A4E9ED0dF125d66C92D2268", "2"],
  // ["0x8cb2cfd9e159a752d61ae4f5fc502c9d211444fa", "2"],
  // ["0xf6e16701911C6C2A15307E621C2A77CB82ed02A2", "2"],
  // ["0x6b8df5e554f979dc93905ad42e0973349e4880c3", "2"],
  // ["0xf5d8edca6514077a57e66479336967b5daa8217e", "2"],
  // ["0xede5ac86f63641d66577b58405cb71408226f501", "2"],
  // ["0x66666666f6d4eef3bf30a60dde1bf50ce056e87f", "2"],
  // ["0x101215Fb12Fa6EB7F8F4B781C6EF12834Bd7e9Ee", "2"],
  // ["0x4cd18f24ecb53deaa217708252e5e8a4eab6adb3", "2"],
  // ["0xA99B75f29a1A6b9cEED5c66Eb01524378aeac4B4", "2"],
  // ["0xbc056a578e26d40f1d34a698e60c309e391974c5", "2"],
  // ["0x1ec99fd16a034da8ffc747858f8ea74daac0bb8b", "2"],
  // ["0x17095f3c01e220fae479eec2d55c4840c72a7020", "2"],
  // ["0xf9c6955714f7f4901c9e80b1b39bb26586b22ecc", "2"],
  // ["0x3a6e2769ee3f543c54a9e84a83659a962494d22c", "2"],
  // ["0xcf61d17ace0a81d8703653e03e692938b7639879", "2"],
  // ["0xcaf07631229bdf0751d90fb55f6bd9339ddb327c", "2"],
  // ["0xb9b7eac9851d0912a96912c9bfa6cd599908c17c", "2"],
  // ["0x89ad55797cc8e511e569e8719e53012f341baf15", "2"],
  // ["0x97f02a17bb88c0241e1081aca6a26e3e04ea3e61", "2"],
  // ["0x51957a18C5500ab48e8de00E8024473411E273bA", "2"],
  // ["0xA314dCca9d7D139F03A4279Ec5d91D08e0dE836e", "2"],
  // ["0xa4f7ca3ad2fe58218d76785499ee40fe11dec889", "2"],
  // ["0x6701e68F0570e6546bc0eCAb067Fd2A539871AC2", "2"],
  // ["0x0CcDF93F055438afEAeA50B33077409c9538f1D4", "2"],
  // ["0x004ae75e6e1065303eea5107cd2be284595d6bf9", "2"],
  // ["0x1a1Ce8054976a38dB8027f1e857478eA423a72E7", "2"],
  // ["0x9bcdcd99ba9d522e97522a07c74470762ef62c64", "2"],
  // ["0x80cb1581a6597012afb7fcdb4853443e22f5cdfb", "2"],
  // ["0x7b0f8faf7f0e035bcb26db62376f5a0df571d543", "2"],
  // ["0x9a7bffd0e6b4d5ffcb9bf3462eb375682a2073cb", "2"],
  // ["0x84f83d84450f5b382e6ffc75026e19c83c9741a1", "2"],
  // ["0x8003f9887bB94dEd54284de5b87E32aD125815Fb", "2"],
  // ["0x511eec9e081a868d4021624a8c6645f2c64a8042", "2"],
  // ["0x481f56a681fc6159a644277a689e446f02cfce19", "2"],
  // ["0x3FFB80002ed7EAB5cCc2A62009C494041b1fdFD4", "2"],
  // ["0xC33b99027b66953Bd4ae8d73D67ecc2E08543a29", "2"],
  // ["0xd91aa66f9367c3eDBe3F5121FF158c902c09DAF3", "2"],
  // ["0xd26f7F6B3bDACF9B34AA3884962FF0d4F151c7B0", "2"],
  // ["0x0b830DF3D03B0190f60e330642152583CB9B0403", "2"],
  // ["0x1FeD84485b7fCB8fFA660647EAD4EF26B88ABb6F", "2"],
  // ["0x8C08c935483e02Ed5b0285Ce4a7bCd92B61dAe9e", "2"],
  // ["0xFc4CEC337064792d3bdc4B1D9903B31dFC52FDe3", "2"],
  // ["0x0C7b7081c123BCECa630A3e0555Ab829C71B11C4", "2"],
  // ["0xd5b79319E05ef6cc25d7ae4ea6616673D3085cC3", "2"],
  // ["0x2e94eccb14ED0a0DFb2EFFc3357f41B10eDB1E92", "2"],
  // ["0xf9cdd7277CABEfb75d2F099841CAc7C6d91d4566", "2"],
  // ["0x12dEBDFd9Dd951A6B5432f67BCb30643A1439651", "2"],
  // ["0xbF0a44954F37C9DcE6b9B1Bd779088A8324BE8eb", "2"],
  // ["0x7029f1f920b84Bdad362c7f96913496c30fa3FCd", "2"],
  // ["0x638877Cf3BDDfd8C841ac6E7D9357428D7c9422d", "2"],
  // ["0xF68230f6395941fEa1145F32406d199695ba4056", "2"],
  // ["0x8F07095192972C9555fb8fcfcd23A46e291d147E", "2"],
  // ["0xba83245913a149572A4aFF9e64eA8E31Ff793aa8", "2"],
  // ["0x53Fe0883f4C141a46860853e5a2f9A1a810dc5A4", "2"],
  // ["0x760D824Acc88a1529f5aA19807aBe934E28b062c", "2"],
  // ["0x5232Fc0E6E992732aF2c8Cb7a5cc7a4372ab3605", "2"],
  // ["0xd4aC486D86B19176458B0a8548E0A25d56531D77", "2"],
  // ["0x9E38833aE7f5984069A2085b422994F865668317", "2"],
  // ["0x4d46fB7c88F521B747e377b9ea466e0CF6F32f66", "2"],
  // ["0x341107ADF031ED03B0db50f78307bAE611d4b011", "2"],
  // ["0x84Ec3BC7d41a67879F9eFFe62A7AeBD868A87850", "2"],
  // ["0xBd8eCFbb27bAF0b56268F96d1d294556a150bAb6", "2"],
  // ["0x790C3a09F5C76Eb9d8b61AEd7dae3E21E8F982Dc", "2"],
  // ["0x31AbECb451bb176553F70e315E60C8bc002D9814", "2"],
  // ["0xB4c6c24b359397f6CdB3eEe5BAE3Fe12b2e7ad08", "2"],
  // ["0xd84B708dCADD8e8fB1ADd938039472Cd4a1d9b47", "2"],
  // ["0x97A2a93988EC514F9A7260F89712B78Af48EA856", "2"],
  // ["0x71dcB19A6D322C9D826aA6b61442828436Aa6fb2", "2"],
  // ["0xE677DFC7185C6dbF6d5484b827160904e870F0f8", "2"],
  // ["0xd882F54181DEfFB64FaFBF7813d7361C57948091", "2"],
  // ["0x21507b0080467a1f940F861E1E6A7e4f167C3608", "2"],
  // ["0x2cc42be60e5328748ac4f87f39cec09c6897825a", "2"],
  // ["0xf657783626824e8F640d4b1255598938A435fa94", "2"],
  // ["0x5e5B3A90296CE2E796033cb381766CE2fe8dB4BB", "2"],
  // ["0xf969b37aD132E92C7c4b2295FEdb435e29d39631", "2"],
  // ["0x5bb82582a0eD5F3486dcAc0f17f2E2a39C817F61", "2"],
  // ["0xC7414ad78747c39CE52218189d3609F675aC81E2", "2"],
  // ["0x15C3956630D419843086B2Dc01992d451a685555", "2"],
  // ["0x3d0c604630D8586FF56FDAe407FcBF33190E28d6", "2"],
  // ["0x4b2DB67426557f647460Be5960c5FfC3ce001df4", "2"],
  // ["0x4B88e31F379FF23Cc2bd781e17d4807d2221D9b5", "2"],
  // ["0xBB14040d6244B6576d6c914f023C7f9774AA09F2", "2"],
  // ["0x38b0B5A660764c866f6FeAA3AD66203f87ae7779", "2"],
  // ["0xA3599Cf1048930c9E897fe9245Dd95511CD16F1c", "2"],
  // ["0xB1746B7a8002e56a80bD8f49eb7eA4C13396E7E4", "2"],
  // ["0x51D4A22Cccc87257014690CfF7E7123f2f2228CC", "2"],
  // ["0xd02840d5853fCddD802cc957D00b7Df04a63ee5e", "2"],
  // ["0xC59780e0a12F55d6b93788a798847deB10E28060", "2"],
  // ["0x892eaf46a9C1CB75c3F230420DCbEE6320c26932", "2"],
  // ["0x2A3f80d1DDe558Da59cc32484E7541149f2B6D48", "2"],
  // ["0x2daB6D6360E29fE9D488b2384E5e47AC4CbaA252", "2"],
  // ["0x04E29Af8a031702d423E78D14B0375F753fE88cC", "2"],
  // ["0x00DfB0d32722D7820DF685E1B93f33774E83892E", "2"],
  // ["0x950d2EE19F602118Da0a3397040b9ADdBd994761", "2"],
  // ["0x82d09e0F426C8bf74e370648aBDc1A6Ee09A4D72", "2"],
  // ["0xc9A5f16F0f9e08ad7CdBE40749036f29906Dc1CA", "2"],
  // ["0xa8DB5cc795F1eCb37333Bf895e4c498871b0AD43", "2"],
  // ["0x54680970e765DC8910cA8f0459D06771e3A664D7", "2"],
  // ["0xdf873953d8F49B589852B14d5ce1a72E1acA9de0", "2"],
  // ["0x291701880f96117164108e4d1e81214d62d71483", "2"],
  // ["0x9070b47085d60bb264ed7e4dc8822cb7033ba065", "2"],
  // ["0x7d2843507ae0344602ebb8c49539d07ae46c0839", "2"],
  // ["0x70Dfa8544eaB74F1E0678E892DB6f1eEfE4A17E6", "2"],
  // ["0x271543647cd83d79bf9e4c52d6acf490c1719867", "2"],
  // ["0x38fbc108056a4026cff01df8aaa3a5bd03d1ac36", "2"],
  // ["0x168a1203b278b303737728b608a439f98aae8144", "2"],
  // ["0xde8b953eafd05c63d12547bce980117267f38215", "2"],
  // ["0xcff611243c16f57ebbc182a9c9e087b219dacbdd", "2"],
  // ["0x16d35043a535ff41941b955e212da903b214b899", "2"],
  // ["0xb62aeb3ff79977ffbf3d34010ea2656cbe685d98", "2"],
  // ["0xe7d1f7b28c225200c813e7b354a03b1a2ccba92e", "2"],
  // ["0xccfe925789739b4fe832b76b1623add09c8dec36", "2"],
  // ["0x4c7ddFfA2607f30daa384365271AEB9a6180Ae02", "2"],
  // ["0x255a03390e9248844203643cfe8aa888c4865af4", "2"],
  // ["0xa4856a7586fd0f2eec5bdeb49b7b8cf80ce3ddf1", "2"],
  // ["0x22612c17549f86fa8b2115e5b62c664960c404d8", "2"],
  // ["0x6393d83cfbb7765e419ca3358e548bc8bc66800d", "2"],
  // ["0xc81418d0bcbb5bf03a51c7a4a61a34df74a5a043", "2"],
  // ["0xa37bbccd480c7758b0df9d4d81797e75e63c6b13", "2"],
  // ["0x069a6b2c9f9e45766fb9668e6893f1be5e09fc65", "2"],
  // ["0x7b9a7d69832f353e54d65188473dde2a7789f612", "2"],
  // ["0x4f5d007454a16c897ca9c42dfeb50d6d8fbba50b", "2"],
  // ["0xa679298d68bf7e539c0a4785393e7b1d61015f10", "2"],
  // ["0x55a62ded73cb6cba91fb2b318d255d7c400267ec", "2"],
  // ["0x112630ba98300a8fb0af4eddb42449c03fc65a58", "2"],
  // ["0x5c264ec0ea52eba19a5981ba6738cee0f09818e5", "2"],
  // ["0x5395a6f42c32cb0060b54ab0f26a06d0c404c1d4", "2"],
  // ["0x2a66c5a57c3d8b6ad6546312a7e7f0160cfbd427", "2"],
  // ["0x0766888adaf83aeea250865b2273d619de133cdd", "2"],
  // ["0x10c5d48b6b4b64d2f6c5e58dff55aafabde17709", "2"],
  // ["0xdd2f6dea3a6f88b350d15c460460529785170c25", "2"],
  // ["0x4401b2cdd344cc7972bfcf7d7a1c68cba3c4543b", "2"],
  // ["0xd44f47113bfd46b47a5f387b2e7b23cad17cc9a5", "2"],
  // ["0xe716de31df13ed904bbc0a0e67aedd553b5dc6f6", "2"],
  // ["0xd87c5df96d2d49d0175ca228bd734f8644e8ff23", "2"],
  // ["0x1e0b43c9d9350a56c431025ef97f1750ad3053b6", "2"],
  // ["0xcdfab353beb4cda5c655bb8ab06ae3b4ec80b366", "2"],
  // ["0xb5348f61063ccb73ca6810eb8299edaf6c6ab26a", "2"],
  // ["0x39a642b7c5ced33fff9aadcf983693f42ab559fe", "2"],
  // ["0xcf937145e05909aa01f2275250028ed97e7eb8f5", "2"],
  // ["0x17420234f2525e72156f57c79234a100060746ac", "2"],
  // ["0x2d801b634331d0b260734b6f1d8064c6101bacc4", "2"],
  // ["0x181563b2694e06072969e6e30469a47896a9f763", "2"],
  // ["0x12314c256835A94cD0038704de11D394E341b36B", "2"],
  // ["0xecd3637d5a13d05c0560fdbfab3edbce2b44f729", "2"],
  // ["0x510c206594cd1918ca6028d856cB35109809DBFa", "2"],
  // ["0x1f25e9727b410ea41979a10aadd71a23a32bb107", "2"],
  // ["0xe7d2b218b783d9239bf287badd4fac64667cc006", "2"],
  // ["0x7e79701e4aef422fae81f24347ccec6865340316", "2"],
  // ["0xe3373c53f7e1752ce5c923822cf5801453704b7a", "2"],
  // ["0x4df0050824f721cb7a613a270e539902c01ea3c2", "2"],
  // ["0x6c5326e11ba87caaed6f4980a6c721b130a131ee", "2"],
  // ["0x12da8aa12eaf8c4344a12160b5822bcf856ae5fb", "2"],
  // ["0xa7f85e8ab49d81a8269bdfabfbf7f75769a03126", "2"],
  // ["0x7e6d9a14a4c859e0e6eb38dc1a9e428b8a13649c", "2"],
  // ["0x31c4a92e3f21ee878e386853e555ad758ef9aa17", "2"],
  // ["0x1a0d4d5b4f7f51e71a88bf2b70177836ac893225", "2"],
  // ["0x64f38526800d406ac92c6e7e35448bb17abe1a0c", "2"],
  // ["0xafa1097db93068ee8675e14d32028b7e7e5acaf0", "2"],
  // ["0x95cb432823277abeb69d13cac2d329eefe92bfe9", "2"],
  // ["0x7ba43c2998c833c11565ee4d0deea015ae5bce75", "2"],
  // ["0xcc29d64c285f22d3b55796803e201488dd666906", "2"],
  // ["0x721441c4254b2ca2bfc454a399803494380d90d8", "2"],
  // ["0x7e4133255a9b798a414f841ade5e90d6fc644217", "2"],
  // ["0x1e6223c7317fb33c1005535d8a5f9e3bc3671c35", "2"],
  // ["0x6e282e9b8daab5fd9caeca5cf6c92a38ce7bc616", "2"],
  // ["0xe1ae2073aca222fc02202b93b3c2395d94796213", "2"],
  // ["0xb13d5bf341c2bffca35bf6dacce8cfc19402996d", "2"],
  // ["0x93b00caac54906ee4ac7c62d4571eda5bc1ed245", "2"],
  // ["0x4f95fbca2bc0745f62e9d76ee6d18709ecc8b6d7", "2"],
  // ["0x81FD8463e14c760676a911B3b6e2622a06cAA18b", "2"],
  // ["0x3acf405b83922a815d8fa2c6977e45cf4b7deb23", "2"],
  // ["0x6c15d3a7ea840a8cb2884056233b8aef8e6e269c", "2"],
  // ["0xecbe3fe9881ab1aa8dbcef9c335c62dc17cc72d2", "2"],
  // ["0x0b6c17d16d3002bb866ea01b89bfb3c6dd94a1a0", "2"],
  // ["0xb8a06ffd4be27a42bf1e8a2fec2844c25e3e66e5", "2"],
  // ["0xc392ce653cd616cad812315e3d111c00b43f517d", "2"],
  // ["0x852344da4d2c1a387444775a4e65b7b49815048a", "2"],
  // ["0xecbe2b8e5db08c9dbf39fdcb68ef01ba9ca3be4a", "2"],
  // ["0xcea9f9e08954c2696058c327fedae2ac0ec0ddae", "2"],
  // ["0x258cce25ca74fa0867a2c875a47f71c46b79d969", "2"],
  // ["0xc3de99ab7cce920aac7835e5916d12ac61c5ca07", "2"],
  // ["0x6ec41178315880ed501183d8877b522ed8611930", "2"],
  // ["0xd930e58c82141764d5649b649ef8e53fbf49ca3a", "2"],
  // ["0x98ec10ad6d59ad1bad41f976358b7b5e82e400a1", "2"],
  // ["0x2929bc564bd8d7b646286e2A3E4Fe182f3634e2b", "2"],
  // ["0x3193a1c60e92e96e181020b5be812879e4b4f189", "2"],
  // ["0xa728dc072f2a50a0208c6e78fada526cd2100df8", "2"],
  // ["0x5bb1d72c002d76da7327e51f21005215fb858d92", "2"],
  // ["0xb630abd9a5367763b7cba316e870c4a54064cc9f", "2"],
  // ["0x88f964c4cce207eaa77cf9ec77e0a2e716b6f1f8", "2"],
  // ["0x728e3bc6f89e00c62177b32a61d1d82829547b0b", "2"],
  // ["0xe08c6a7eab5e570f2fe3e41f20138ee1ab5d87b6", "2"],
  // ["0x8a93e80c19f717b913dd028f12042b1ca08e8ad0", "2"],
  // ["0x0e72c6d24f3f9e14ac0850d598c781177c8a4c12", "2"],
  // ["0xd6b1542d79f03e9cddf91d205614184ed05de2a5", "2"],
  // ["0x29f759aba1afef7b9df38a2725ac9c235ccf6847", "2"],
  // ["0x067cf60192bee08f8a9f6941858267d009203b91", "2"],
  // ["0x643a5df31a00791e411d61c710829b56aae64c5a", "2"],
  // ["0x202dcb3c9a06637fd994b24d46003d918e448ec2", "2"],
  // ["0x623cb5eda2319be3c8e552d1e9b1111b2177916b", "2"],
  // ["0x6e4c4c8dada983f97a39ebf8a21e734958d13d3a", "2"],
  // ["0x35201d0bcfcd5fff8e168df2566f0dc2db09f380", "2"],
  // ["0x9d9f7203fae9c00f4c265735023078d1c2a9cb4a", "2"],
  // ["0x6e999f6540d5c816ac3187f9193b9f5b27a6dad2", "2"],
  // ["0x1971825fd1b7eaf3a248d7cc91200c7465b9e46a", "2"],
  // ["0x5173b653d926e79e4d0092540bba495f5d40e9e4", "2"],
  // ["0xbf93219aa7c2134d4f016b073e252ee3229de2b4", "2"],
  // ["0x46873897f487119f34ffde1e6819dbda90cf8acd", "2"],
  // ["0x24f854c69a7f654dd8769ac215f6f27c65e71fbc", "2"],
  // ["0xfd92f0ff67b6e2900df57df5aeeeaa4e68c30c59", "2"],
  // ["0xb29952e03f7dfb760adcbeb0e4016188eaace265", "2"],
  // ["0x100397e7f5ecc0f3d6ef46a25c842d60efe30a7d", "2"],
  // ["0xad98b77c501e0e8a151fe33996e766882e39bbd0", "2"],
  // ["0x69f32dbe156d3c5c116ca8fec75eceb5148841e5", "2"],
  // ["0x4eb9a02883f13610a21da2bd8fedd842078a0c93", "2"],
  // ["0x4b5b8c41940ab49b8211af3c55cb70c546cc8439", "2"],
  // ["0x15d5cf16fa1a89ebeccb6d3ebc78f8b05724d265", "2"],
  // ["0x3278f987d92ad2da6b35f2ffd027287d28224299", "2"],
  // ["0x6f0d890c629a3e31e8f4620d113e3ef670fed54e", "2"],
  // ["0xd53c48759e516cab89bbc36b57128755d0b8f691", "2"],
  // ["0x877167231d569c9fcf5e7a5e2b073c7cd41f2cff", "2"],
  // ["0x5b54f8a64087978ef37ac5372261e9bdc6da645c", "2"],
  // ["0x4e8af129541252b506b284d00228b13bb80576f7", "2"],
  // ["0x70ce6403488697b6DBe6044f88C8d6272c28503c", "2"],
  // ["0x81dcab1513d88478a3536348d6d7560f2d8762a4", "2"],
  // ["0x5d10109b42802940675a42d3d31688ec86a15eb8", "2"],
  // ["0x161c5e8595468fc739ee008afc272a0110b2d3f3", "2"],
  // ["0xc97cc6cc4b07ad3f5919165c99ce43437d6114a1", "2"],
  // ["0xa6b443766ca03a35e39c9207ae1e499ac3b41662", "2"],
  // ["0xf1BD18d6bC6ff62e732BEb3ea827bA77B874307D", "2"],
  // ["0xF6a9b29Acd34a8Bf1aE48fe1525861486f853D75", "2"],
  // ["0xd0B59C1DEa04AE48F64AE7d8FD9c47401CFb49dB", "2"],
  // ["0xA71061DAB2228346D89cCcC11BDa8629cE898251", "2"],
  // ["0x1eAF32Ca284016bfD4af0f6d758775f24d946e1B", "2"],
  // ["0x112d62B9B1DeaA943e8BEfB7270A9167c7b95838", "2"],
  // ["0x760CaE3f027F8de561509a48234F3a8Cb5029cdc", "2"],
  // ["0x47e07899fF4EB073d81aaEB58Fb51E77db744Efc", "2"],
  // ["0xCF9263A1717384df814Cc87eb67d6Ad46E629dD5", "2"],
  // ["0x18246CDf6680712B57b355594D0C339163B33058", "2"],
  // ["0x9ae9C9ca57AD2d3C7E678cE21ca4F50986E31c65", "2"],
  // ["0xdbD77d599088D8Ed8AFB2c78e26D1A6B0c034f0B", "2"],
  // ["0x94c6357747a2Cd953b4d021Ed1d63Dc9645e3445", "2"],
  // ["0x1126fd454D0BE14A0DC0AeAA09b930E50297021B", "2"],
  // ["0xB54ddeA1C9B2C0Faa0b946eDc3699F2c287Eeb31", "2"],
  // ["0x34296515c9fC10d1aB435c4723dFA7EEFD566915", "2"],
  // ["0x7Ca2a660A001a8088bCBCdc5FCC74062321D36eb", "2"],
  // ["0x3012238be3Df637A39313d33AD8C58961D89ae7e", "2"],
  // ["0x642d6d9b85dA6BF4B8b95228eb6F36B57dCEBa3b", "2"],
  // ["0xeAec707559c1Bf3613B5AFB8059D946DCeAE81b1", "2"],
  // ["0x5c530467F271351D028a91ebcEaE128d3F43ccD7", "2"],
  // ["0x67F40E737F9647cfc371A5A9fB551d950d748d9A", "2"],
  // ["0x12d1e17a4c36d494a2b4c05bf0e6fe97586f3b21", "2"],
  // ["0xff452CC8Ff7DeB713B6cf5AC5aA6085C0A606786", "2"],
  // ["0x167C83de68de9469090D1ecD1552742e6C696474", "2"],
  // ["0x29f70239E01FeB4602962c9a73b8bc89ad7F9aa9", "2"],
  // ["0x08B2F5d2e642Db210cA1ECB8235099c63D9c0E22", "2"],
  // ["0x9D809ba7b762Cd3a9F54D334b49c16e4Ae223c59", "2"],
  // ["0x14348fF27BF4381FFA362E6E751A3d1E8211aa74", "2"],
  // ["0xfb82F8C838Ba2de8C8FABE225485656afce1Ed94", "2"],
  // ["0xE9F46E0901A44097ab9F493C253D67dF382bDE9E", "2"],
  // ["0x791faFB984A62fCa4B58d219549a1bEFBbcD8067", "2"],
  // ["0xB38C3B4243cdaB57158Fdf2C6B578FAbD5AdDd75", "2"],
  // ["0x5bC1e1a13e03e5b201b41D16976D5BeCb4E9e150", "2"],
  // ["0x648cB649d4c8343DFfD70417B38221E4f62aEBbD", "2"],
  // ["0xb1F124ebBeF436B53Ce1F36Eb12027F4f6e5ef07", "2"],
  // ["0xc070b2DeFFad436C37f7d20E518c92256F6d261c", "2"],
  // ["0x2eF298A5fe87973EED8B45A68713cE608fC707B7", "2"],
  // ["0x455e69883fd10158bb1b69aBE6f22859948A1971", "2"],
  // ["0xf6CaD61ed398289B480c916A54cAc99b3575309F", "2"],
  // ["0xE0b865991422840BeB4E00562FCB1bb269be4232", "2"],
  // ["0xB4d7f0d0813dBfA1aef332c986854f3bD53fAE98", "2"],
  // ["0xcb6CF8Ab5f5C141127516eEB9102803bC98852DC", "2"],
  // ["0x04D21350C04E7ba4f9B9AAE31C1209915f50EEf0", "2"],
  // ["0x2d91E1270f25363Ab531b19e0D52144f992e2b8A", "2"],
  // ["0xD26bC7C966B4da76Cb7943A40A29e881C3F90a77", "2"],
  // ["0xA87138CdF3bA77E0420B3d430C7A708a7Dd42a17", "2"],
  // ["0xF023A7D4a47CE0858F2883797B8158A02d8f263d", "2"],
  // ["0xf86aaEB9a6a8BBa1E2C4317e771E1CA044449799", "2"],
  // ["0x32d0C585688CFA25dbCa43741E5111a70AB4fcB8", "2"],
  // ["0x14c98341CF64D6155773839A2E5E71311FE4577e", "2"],
  // ["0xE39363ae11723E3dfc2A83d366F83AB8d86aace6", "2"],
  // ["0x1AF1c63f2b29021C33c24C7a5c1c20f6593bDDd1", "2"],
  // ["0x9dd7604F02f9BA324F7CeA8F08E8a1945165be9f", "2"],
  // ["0xF4821DCee5a6F5Ee4fbeB1A5708cd8138cB7bb45", "2"],
  // ["0x9dF92Bd4A7191FaBf7B2afDc23589360356B6993", "2"],
  // ["0xBE754Fe639617E95879139dEa38e50524d51e026", "2"],
  // ["0x028d5EecD2e80310600e2b08527ebc1485CFB0Ea", "2"],
  // ["0x455b60abf88ee429e0e518b85f0b9ddcfcfc0b1a", "2"],
  // ["0xf3302322E9Dca493B27432419CFf568A629Ceb42", "2"],
  // ["0x9e118E4377008CB395e51493f36dDae938F355F3", "2"],
  // ["0xC5eEcA42De080A546554977A955288C5C298f141", "2"],
  // ["0xb1A5655e08b825438EfA48228161CF3e36faeb42", "2"],
  // ["0x6B5310D7783DA9f42d4ea19c4589a79A8619bFD2", "2"],
  // ["0x61e480A6F2f539c5C8b71894293b00818b2D6E73", "2"],
  // ["0x74Dfc9dB505dA780b401474c708Cb7b4B57Bf3c9", "2"],
  // ["0xA7fDD5b6EBfFb25B2c9F206505c1263E498f0333", "2"],
  // ["0xDDbe90c74d007687ECC0C41baF0925E81d0f4674", "2"],
  // ["0x2604704C9c44c6000d45B33af7fad2ef8E02DbF2", "2"],
  // ["0xB887A81683ed3cD4a5C0414C5456B6D7F0E11b00", "2"],
  // ["0xC8f9711387A6E6966D039B554d656a5c375cA97E", "2"],
  // ["0xFC397502e11b8e08935Df2295eCB8A79D2122975", "2"],
  // ["0x4652d13302ca020901bd69de6443997a5d795630", "2"],
  // ["0x86C11c0c35E7346aa9fF4A4cf0f9AF63b4996199", "2"],
  // ["0x5C4D529f0182128Cb37d8e45e3525Db241124bF8", "2"],
  // ["0xd9556e8d55d6d02e6f54ce06Ae3A18fa7a5cf23E", "2"],
  // ["0x870B901DE303326e34214B54FF0aA5564B912739", "2"],
  // ["0x14a6e823Fc11b629ac8b798f378A77d31dF8537E", "2"],
  // ["0x816681544ad935941e97183ab9f98a61b7aa1843", "2"],
  // ["0xd9A8BcF78a328264F54fd3E8566D840758B1b6d8", "2"],
  // ["0xdAbd0D8Cf23C877030fc6a08ecC8e5aBC925328c", "2"],
  // ["0xB64aC7e96cfED5aA7fAA657c91bE08c61C16Af94", "2"],
  // ["0xB15017aA5d2aA412C04B9A8510b2747f20be950E", "2"],
  // ["0x685984ed6680f201C74F171CD12A34943B07eE28", "2"],
  // ["0xc28036bb255840873cc530a4845617EC7939A042", "2"],
  // ["0x1E45357D36e2Ae5C6b39Cdf622b50651AC802492", "2"],
  // ["0x73Fb964F740E82204C90734aCeF40d8492048B4c", "2"],
  // ["0x8FaBa2243Cd7ff1E7372ec18e4384e3B38E986bc", "2"],
  // ["0x6686F91272cC7C13B3eA54031d6Ad22fD6BA8771", "2"],
  // ["0xfC78658F64ab1E32c85Ce52F5eFAF5D2f3cD398f", "2"],
  // ["0xD1D3c6B53Eb8288437c93A3987425442Eb4B75A8", "2"],
  // ["0x9D20b9b861D34b1445C991da7aEE29eD70154b4D", "2"],
  // ["0x3660A38e2b7281ACc9fA8345c0cb8e9EdDd51D36", "2"],
  // ["0x082702c91C2689aE9d8c8b3Fb1b918d3802aEC4A", "2"],
  // ["0x058F0c32DbE765B9a52086bBb416dff0aeE42303", "2"],
  // ["0xd7A481069fe91E8b8C2A44e62e128f4edB32681E", "2"],
  // ["0x127e87737b4587f7b336A202A91474F3c4f1cb63", "2"],
  // ["0x66c20Bc2C40755635957931c3c6288fAC27187C8", "2"],
  // ["0x66E316EB08E648171599DeAA225e36068f84F173", "2"],
  // ["0x98de2020633A28F231eB2d31CFd039b3E1582AAa", "2"],
  // ["0x7990Ac0d0d602346c2AA05DE66f711a9f50fAF8D", "2"],
  // ["0x7c31882158789b8ec7d07dfec9ca3605834d752b", "2"],
  // ["0x503b79C7eE1bc253e86abDaa1BE9e17444a26a74", "2"],
  // ["0xe8AF0E351aCD5ab5466C442E55F9321725E682b5", "2"],
  // ["0xb69688D28bf0be3D1EE2A15365af813Fc3457e80", "2"],
  // ["0x06A1F68Aa33DEE77f73CB5547AF409d79F296840", "2"],
  // ["0xE11DF27cF73ACAE001b612D0ec293926De060bD4", "2"],
  // ["0x605FbDD3d2e9Ba4966222748332a4137ED2dDee9", "2"],
  // ["0x9D6061f52b2A1032Dc396066350C57B8ba4dA430", "2"],
  // ["0xA26c5F0b89322cd75828d5085Db8164287315df3", "2"],
  // ["0x445938f368763199E0384BD139B87CFCcE62E5f0", "2"],
  // ["0xE2862f3eBF7753179A02e6A6080af9DF03C20DE2", "2"],
  // ["0x6Fe2b131A371D16DBa703db48763D45434457e8d", "2"],
  // ["0x44065bc336964262e55aA0382c6F81149dC579A9", "2"],
  // ["0xfA2BDaC3B74D519dAFA37d29D3320828961a1AbE", "2"],
  // ["0x78DF3413252227C28e11d41Cb0D3a19481592BCD", "2"],
  // ["0xBA285C02e75755CdC9934DbbC45Ea17C5aD65385", "2"],
  // ["0xBcBa66A96B330be24133Fa768d94eb4dEb8cFc3b", "2"],
  // ["0x27e03E7AAE510625fBdF8f57750CB060Fa2205F6", "2"],
  // ["0xB00015df432EACded9d29933EA877A81E00A96DF", "2"],
  // ["0xB0fe1e71a88b115f3b7C4053Bb1B74C5f2B3d19f", "2"],
  // ["0x831fc8800924C7F0aCCe61e71042104e255Be720", "2"],
  // ["0xf5A4918a90D55d7754Db9F2C6dd8181369ad7757", "2"],
  // ["0xC8941CBEFF9422885c95e2dE0506005Bb10994B5", "2"],
  // ["0x9b76aa08528D49641f6b9426D029601a066391F7", "2"],
  // ["0xba961ec90187b8071f52897ed5d84614c11be669", "2"],
  // ["0x94E2616909DFDB6FC95E5FdfAfFF5CD3D25D0DF5", "2"],
  // ["0xcbfDe37Eb0222090Bf91ff4700b7aeCda6bB11E9", "2"],
  // ["0x5Ea60553B6c2e1Cf9d03735C8ddDB303B39F2075", "2"],
  // ["0x179fFF1Dbaf3ab8B49c650f5F0DE02A9C509d8ab", "2"],
  // ["0xdBd6D33dd12288893D9A177e97AC91C5AEcAF30c", "2"],
  // ["0xfeB7bDF0D31239A349809e8bc274d90D31A5dEb2", "2"],
  // ["0x4bFE218ecA83d1F28627D1164344f4e85957796D", "2"],
  // ["0xB2e1c9C2FfAef4883ad7E01Cf4F772346C0A935b", "2"],
  // ["0xf0c583a3C69b6C938Dda01cB34808927755b1EBF", "2"],
  // ["0x04Cba99bf19958470d03BE77Fd1936C1AC73784D", "2"],
  // ["0x9be40fB17A29591f4DC248e62692D83Ab56EAc62", "2"],
  // ["0xD2A6de2eba1D53EfD03f1f4b196eEd8c7BC9F5Ee", "2"],
  // ["0xe6fa90026f53250Ea73dB1c5E38C0b6C468B49B8", "2"],
  // ["0xE10BEE357Bb9c29ccc003E63F5553E57c871cE19", "2"],
  // ["0x5eD463de0A09145BFa40B8E12A3226b5b75de5b6", "2"],
  // ["0x266F66f5a347459a6007B454192fff3De0EB6cD5", "2"],
  // ["0xD4713F98CC72AA1683acd987Ce9f4967a91C5059", "2"],
  // ["0xB0939cd50b584C278f6F65d4b752ebffd985c495", "2"],
  // ["0x6a34dbE821210369823f3619d1Ab3F827e58D46f", "2"],
  // ["0xcC2b2724c4bdf6c40dF06E0644E16379A7879aB5", "2"],
  // ["0x1e4B024b804b6136BEb0a561f566ab0adaB2e211", "2"],
  // ["0x4B0fD1Ed9969ef483167AD52eA742ca3c510018a", "2"],
  // ["0xA7C4596597591BA85f306BEe105C57996dA98A10", "2"],
  // ["0x1533eF8a7BC85532a8515DF6fDFb15d165D0d03c", "2"],
  // ["0x9561bE1C6edA59537D5A10D8dbaBeB024dB783C6", "2"],
  // ["0xAED970Dcd7BDF7966a2a660aC6d78B79F8AE0FdE", "2"],
  // ["0x598dCA708066F60B2CcAa71D45620761D57851D5", "2"],
  // ["0x5a5dC550eC359567997c1b7c9CCe95005F5EFA64", "2"],
  // ["0x0E633B8F6a76c6C23975967F1c59FAFaFE6cf53C", "2"],
  // ["0x47EF26Eb5C4a906C387A04FAA155Eddf05542180", "2"],
  // ["0x73a7397569D89852bFF239FB977aD244C9451840", "2"],
  // ["0x4EC00e1fB7C7C1B88D8ff37dF74b3B548672b23E", "2"],
  // ["0x7f1c7059799066214E1EC67A26C89560BcAE36b5", "2"],
  // ["0x08E8d4A308c410F42AbfC31C6195a3B2C3138Cd6", "2"],
  // ["0xC6e5d62ED1cfCcaE80C4d4b203fEEa7263A535c2", "2"],
  // ["0x1D66779aEd95c90346F266622D59E0AE32F8c0d8", "2"],
  // ["0x2cE8E541eb2F952DA9F27bB96cA63Cc67286F219", "2"],
  // ["0x8f7d7b74941ef09FfeA9647fFffA79e8b6C0d483", "2"],
  // ["0x533BE8603F70070f418c7d53CC68d72D345C33f8", "2"],
  // ["0x15dE017d9478469aBBF7F5C08a2C72b3a93E1BEE", "2"],
  // ["0x66095A7BCb23A134bF97836CBCB2b933836a5ae8", "2"],
  // ["0xE03A7587C9faAe452F27671e942Ff1DE5a358256", "2"],
  // ["0x85B5F4F9368e4c9bA7C8061c8F98082f6A522e51", "2"],
  // ["0xB88720e99eAEF92BA5191526ec9e4EB6F45F9157", "2"],
  // ["0x93c085FFddCaE58F1e77B56ECE21d04D65C6650c", "2"],
  // ["0x7e763E908f391e532C1fE4eF5978EdC92B039e44", "2"],
  // ["0x249bEe5bDdf4837731F2abb32e6277a33fbDb6B8", "2"],
  // ["0x2971a1b8659c43BEEdE8516973fff69bEDcf7aAD", "2"],
  // ["0x171874264599451c074d91d7B1c1E1858BaFE665", "2"],
  // ["0x59AA8Af3e58A0200fcd7b6DE42622C15598deB0F", "2"],
  // ["0x7a3E8311dd89CBf66bAB85f7311Da97ADbCAeC49", "2"],
  // ["0x932f3D995c68b88d679E4e1D793Ccff2eed343AD", "2"],
  // ["0x27F2fC035BFa80bDc064674f1b97e5f644e508B1", "2"],
  // ["0xD586a3162B5a05B0F784aC866099172236ddca8c", "2"],
  // ["0xF0C15C42d12a66A64C18B7B3AAAbD301850c2B67", "2"],
  // ["0x5C45599120E597770B8B78E0d619219c7721F2BD", "2"],
  // ["0xf3e6639Ec6e0A22ad89351c92cF2C6f6bfd8c560", "2"],
  // ["0xb94AD32Ef05eaD999f8CE1Cd68Ed94D04F07c50a", "2"],
  // ["0xf2Da383844B0C97bA011DcbFBf49BC6a54c308dc", "2"],
  // ["0xBC1BCaC4C1C1B82D09c156423eaD75c05ce58404", "2"],
  // ["0x1DCE0aF42458541577b400A2A730F69BAed8378e", "2"],
  // ["0xD8cDb96b3CA5Bf5F68f8376CEC752ff6946746A6", "2"],
  // ["0x75CdD8B65E60ea99B3Ae9A9beCDDcBfeDd695EcA", "2"],
  // ["0x45585d02Cb7eC7e4e05a47067B038c39613c62A2", "2"],
  // ["0x0B1fE9e816937f7Dea5c7E84E8b4bD25b01DEa85", "2"],
  // ["0xd7d7a039725AD4C40CE3131A6377363Cf0085221", "2"],
  // ["0xAd4cC9FbC767249c37451aFe588D69Ff41f71752", "2"],
  // ["0x6856522A47dA76BE33f0F1fCaA519D2A1449AA16", "2"],
  // ["0x85474e84d044ccc342d4213abd4ed6b169636351", "2"],
  // ["0x110382214B18D8d7F834BfB497F3976c2090fe9D", "2"],
  // ["0x268eebc2f5d8a3B33D7b318507f97DEB4c7F19ff", "2"],
  // ["0x45d017a9Dc30c4baccC0CEfd2a52FafeAeFbC374", "2"],
  // ["0x887Cc6D776E28A624c61F46220bf278c04336b15", "2"],
  // ["0x37982327e68eA62cCA9E1fb7d88fce2790aC601D", "2"],
  // ["0xCFC8d394Dbfbb5F5F7b9fdA8F0C48D62DFB39C9B", "2"],
  // ["0x0fda836B57131DCdC72c1bC609f7EeE1d87e005f", "2"],
  // ["0x79076943C5CADbc071951386E94E28f4f5611FdA", "2"],
  // ["0xA2A4431f919492932A88478e6bd68Eb9f6281148", "2"],
  // ["0x5267d63f9f9e68c56ff026b3791b07b966d21a0d", "2"],
  // ["0x3Be3f923d6F20E1CCDF3d517Ed22960269E8C703", "2"],
  // ["0x968406C26e1048d63B76B5897aBae680dA611C9c", "2"],
  // ["0x4DFa15DA23305035e17E403575B3D95b8726952e", "2"],
  // ["0x11fBDdc4e34C6bCf0DF96D6AEcd01d52E252098c", "2"],
  // ["0x72DEC415BBe8Ab5466801beaB5AE2E6fa622c813", "2"],
  // ["0xcd72b8ac536cc2d0ea6e088a35d9de9e6dbb4b93", "2"],
  // ["0x1e92C7B70Bb5F0298f7CEb32a7B9C72A4dcB69FB", "2"],
  // ["0xfdaeF36efaE3f9427693FfAE7Ca592d9A6598aa0", "2"],
  // ["0x0423dcDb3DA04E517a0929ACadbA32626e2959EE", "2"],
  // ["0xC2F8cD9EBC8eFDf2Dc38E8517944a4dBB3923E45", "2"],
  // ["0x81385Fe847B9992F81DAa752954Fcc4225DE1BC3", "2"],
  // ["0xf32C9d557eed21dC8e08E4aCE3E5C2ff20bbB11b", "2"],
  // ["0x7F211c5C695E799021D71f18FB0d9638B2fFee8b", "2"],
  // ["0x02547810a0fBb674748f01d5aE12FBC674C3E94A", "2"],
  // ["0x85D61866C75f10fA37Ab2fcF19371F01d34B41CF", "2"],
  // ["0x748bA060910337607cC596Af39fEDc3E88618101", "2"],
  // ["0x5AAADA8f32EbfFb3FB0AbA8A0DdA605f0C2D244A", "2"],
  // ["0x152EF36C361a4Eb052A95CD5c6f72690715BBED0", "2"],
  // ["0x9f17266dA094b59cda6779F2c5e3Bd05a08E8c87", "2"],
  // ["0x6c33D9992d8B933eCf5A625cC0E46305A04a4268", "2"],
  // ["0x6571757cD4263ef73ec1CEF147d469a51805bEC1", "2"],
  // ["0xDD761DfE9a0a3aB33Fe07F7940d049FD24f68952", "2"],
  // ["0xaB779C827E7113a642C643eCaec27BaBf1e75870", "2"],
  // ["0x2D3fd9EE49189959368448A5cBc63213121D66b5", "2"],
  // ["0xD8cBB1F93f9356A9260c7F454f09CA9130b6FD8A", "2"],
  // ["0xA6a3A04E164bf70D2dE18cF2496acdB7aF3fB049", "2"],
  // ["0x6BA6a7B82F98799225B7fd11e188518EDD3A4f1C", "2"],
  // ["0x054EAA6D78D284ff0693945f315c471E74d69129", "2"],
  // ["0x835d3cda10EeAd2050EF4da0f418FeDd364bC657", "2"],
  // ["0xb78F103De81747742B46bfd035764FD4734c80CD", "2"],
  // ["0xCA98Ab27F0902BD467AF545a4E42dF2ACcF14fc5", "2"],
  // ["0x19d2e1673c1bF3e38ea3280932A009ce922b8348", "2"],
  // ["0x9A05030125f7d94BEf05D07B752EdC3D1F29886a", "2"],
  // ["0xd2Fa9640713d83f534CE78E9dAe7D0f18AE702ed", "2"],
  // ["0x7a4B5024790B45479f65da6517fAe15f48FA7BdD", "2"],
  // ["0xe423c54759E9340240c6Ce550d243049c264696b", "2"],
  // ["0x883FFE0c8bBEd92f9aC136A7Ad0E3Ae0b287191C", "2"],
  // ["0x9d67E0cAdAa30ea632BfBeE876343e9057865d87", "2"],
  // ["0x054D9811F1534F2cc25117E7612d66768a740581", "2"],
  // ["0x58b1281F9b9C9F97cE22B86f4D63A07C9B0b621f", "2"],
  // ["0xbd779987C087e2fE385B25D0a8aB71Fe81748E4A", "2"],
  // ["0xFdc924FbE0FD130406242A828E8c7D564f57ee9b", "2"],
  // ["0x99F85c626AA0829b3B6681Cd6A049811D08E373D", "2"],
  // ["0xDeBfc5dE8efA54E7715683393318eBf165413d6D", "2"],
  // ["0x6079a2bc8a42Bc1f7D85DD3A235496862942F845", "2"],
  // ["0xdFe51f6F0C5a9f02A1d0A995b688E0B9dAA9B4dD", "2"],
  // ["0xECA1bB9c8d3Fd8b926372f42c8D4c6c3ed0669B3", "2"],
  // ["0xf51d60Eb347040552DfE258cA0091c7F6Fff8F57", "2"],
  // ["0x08B5bfEB412443cfc54E38656Ae88f6bDDe4920f", "2"],
  // ["0xE8FF1f9029c6e9759D3C3A344161c4Fa229d441D", "2"],
  // ["0x0E7270c2458dB4702864efac206a4896AC55e355", "2"],
  // ["0x61473ebF25cFeA1C29ac10f0eFD243D83C5B81C4", "2"],
];

export const presaleWL = new ObjectMerkleTree(
  "presale",
  WHITELIST.map((x) => {
    return {
      address: x[0],
      max: Number(x[1]),
    };
  })
);
