// third-party imports related to the use of Web3 and MetaMask
import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";

// first-party imports of types and constants
import { contractAddress, contractAbi } from "./_infuraNode";
import { FETCH_WALLET } from "./_types";

// used in the event that the users wallet is available to derive the contract
const Web3EthContract = require("web3-eth-contract");

export const fetchWallet =
  (which: string = "initial", skip: boolean = false) =>
  async (dispatch: any) => {
    let payload;

    try {
      if (skip) throw new Error("Skipping.");

      // determining the provider from the extension, this then creates a web3 connection
      // using the detected provider as the provider
      const provider: any = await detectEthereumProvider();
      const web3: any = new Web3(provider);

      // getting the requested accounts from the provider
      const method = "eth_requestAccounts";
      const accounts = await provider.request({ method });

      // `handleChange` ensures we reset the provider in the event that the user changes accounts
      const handleChange = (_chainId: any) => dispatch(fetchWallet("change"));

      // we only wish to bind these emitters the first time the extension is connected
      if (which === "initial") provider.on("chainChanged", handleChange);
      if (which === "initial") provider.on("accountsChanged", handleChange);

      // determining the chain to return to the front-end
      const chain: number = await web3.eth.getChainId();

      // creating the connection to the contract using the address that the contract has
      // been deployed to and the ABI
      Web3EthContract.setProvider(web3.currentProvider);
      const nft = new Web3EthContract(contractAbi, contractAddress);

      payload = {
        ethereum: provider,
        web3,
        address: accounts[0],
        chain,
        contract: nft,
        type: "MetaMask",
      };
    } catch (error) {
      payload = {
        ethereum: null,
        web3: null,
        address: false,
        chain: null,
        type: "Infura",
      };
    }

    dispatch({ type: FETCH_WALLET, payload });
  };
