import styled from "styled-components";
import { defineBreaks } from "../../../../primitives/responsive";

export default styled.div`
  display: flex;
  flex-direction: column;

  img.flowers {
    margin: 0 auto;
    margin-top: 20px;

    ${defineBreaks("width", "60px", [{ breakPoint: "600px", value: "100px" }])}
  }
`;
