import promiseObject from "../../../utils/objectZip";
import { presaleWL } from "./whitelist";

require("dotenv").config();

const RefreshState = async (
  contract: any,
  wallet: any,
  state: any,
  setState: any
) => {
  if (contract) {
    const contractInformation = await promiseObject({
      supply: contract.methods.MAX_SUPPLY().call(),
      minted: contract.methods.totalSupply().call(),
      status: contract.methods.status().call(),
      contractRoot: contract.methods.merkleRoot().call(),
      price: contract.methods.price().call(),
      publicLimit: contract.methods.walletLimit().call(),
    });

    contractInformation.status = Number(contractInformation.status);

    let setup: any = {};

    if (wallet?.address) {
      try {
        setup.numberMinted = await contract.methods
          .numberMinted(wallet?.address)
          .call();
        setup.whitelisted = presaleWL.isWhitelisted(wallet?.address);
        setup.siteRoot = `0x${presaleWL.root}`;
        setup.presaleLimit = presaleWL.getMaxAmount(wallet?.address);

        if ([0, 1].includes(contractInformation.status) && setup.whitelisted) {
          setup.blacklisted = setup.numberMinted >= setup.presaleLimit;
        } else if (contractInformation.status === 2) {
          setup.blacklisted =
            setup.numberMinted >= contractInformation.publicLimit;
        }
      } catch (error: any) {
        console.log(`@DEBUG: The follow error occured: ${error.message}`);
      }
    }

    console.log(
      [
        `@DEBUG: What is the contract address? ${process.env.REACT_APP_CONTRACT_ADDRESS}`,
        `@DEBUG: What is the contract network? ${process.env.REACT_APP_ETHEREUM_NETWORK}`,
        `@DEBUG: What is the total supply? ${contractInformation.supply}`,
        `@DEBUG: How many have been minted? ${contractInformation.minted}`,
        `@DEBUG: What is the current sale status? ${contractInformation.status}`,
        `@DEBUG: What is the price? ${
          contractInformation.price / 1_000_000_000_000_000_000
        }Ξ`,
        `@DEBUG: How many has the user minted? ${setup.numberMinted}`,
        `@DEBUG: What is the user's presale limit? ${setup.presaleLimit}`,
        `@DEBUG: Is the user whitelisted? ${setup.whitelisted}`,
        `@DEBUG: What is the contract root? ${contractInformation.contractRoot}`,
        `@DEBUG: What is the website root? ${setup.siteRoot}`,
        `@DEBUG: Is the user denied minting? ${setup.blacklisted}`,
      ].join("\n")
    );

    setState({
      ...state,
      ...contractInformation,
      ...setup,
    });
  }
};

/**
 * @title Refresh State
 * @description refreshes the state of the contract and the mint for the user
 */
export default RefreshState;
