import FooterStyle from "./Footer.style";

const Footer = () => {
  return (
    <FooterStyle>
      <img
        src="https://cdn.pr0xy.io/powpow/logo.svg"
        className="logo"
        alt="PowPow Logo"
      />
      <h1
        onClick={() =>
          window.open(
            `https://etherscan.io/address/${process.env.REACT_APP_CONTRACT_ADDRESS}`
          )
        }
      >
        Verified Smart Contract
      </h1>
    </FooterStyle>
  );
};

export default Footer;
