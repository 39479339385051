import { useRecoilState } from "recoil";
import { itemWithId } from "../../../../../state/atoms/withId";
import Mint, { Center, Selector, PurchaseSelector } from "../Mint.style";
import { useEffect, useState } from "react";

const Presale = ({ state, mintNFT }: { state: any; mintNFT: any }) => {
  let [amount, setAmount] = useRecoilState(itemWithId("purchase-amount"));
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    setPrice(Math.max(amount * state.price, 0) / 1_000_000_000_000_000_000);
  }, [amount, state]);

  const adjustAmount = (direction: number) => {
    const newAmount = Math.min(
      Math.max(amount + direction, 0),
      state.presaleLimit - state.numberMinted
    );

    setAmount(newAmount);
  };

  return (
    <Center height="300px">
      <Mint.SelectTitle>select the presale amount</Mint.SelectTitle>
      <PurchaseSelector>
        <Selector onClick={() => adjustAmount(-1)}>
          <span>-</span>
        </Selector>
        <Mint.Amount>{amount}</Mint.Amount>
        <Selector onClick={() => adjustAmount(1)}>
          <span>+</span>
        </Selector>
      </PurchaseSelector>
      <Mint.MintButton onClick={mintNFT}>Mint!</Mint.MintButton>
      <Mint.Price style={{ marginTop: "20px" }}>Price: {price} ETH</Mint.Price>
    </Center>
  );
};

export default Presale;
