import { Connect } from "../MetaMask";
import { Socials, Logo, Links } from "./Socials.style";

require("dotenv").config();

const SocialLinks = () => (
  <Socials>
    <Logo
      onClick={() => window.open(process.env.REACT_APP_HOME_PAGE, "_blank")}
    >
      <h1>home</h1>
    </Logo>
    <Links>
      <Connect />
    </Links>
  </Socials>
);

export default SocialLinks;
