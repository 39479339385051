import { useRef, useEffect } from "react";

/**
 * @title useDocumentTitle
 * -----------------------
 *
 * @param title
 * @param prevailOnUnmount
 *
 * @reference https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
 */
function useDocumentTitle(title: string, prevailOnUnmount: boolean = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount]
  );
}

export default useDocumentTitle;
