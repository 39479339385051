import MintStyle, { Center } from "../Mint.style";

require("dotenv").config();

const SoldOut = () => (
  <Center>
    <h1 className="not-whitelisted">
      {process.env.REACT_APP_PROJECT_TITLE} has Sold Out!
    </h1>
    <div className="bunny" />
    <MintStyle.SelectTitle
      onClick={() =>
        window.open(
          `https://opensea.io/collection/${process.env.REACT_APP_OPENSEA_COLLECTION_SLUG}`,
          "_blank"
        )
      }
    >
      View on OpenSea
    </MintStyle.SelectTitle>
  </Center>
);

export default SoldOut;
