// third-party package imports
import { RootStateOrAny, useSelector } from "react-redux";
import { AmountSelectorDiv } from "./Components/Mint";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import refreshState from "./functions/refreshState";
import mintNFT from "./functions/mint";
import Banner from "./Components/Banner";
import PurchasingModule from "./purchasing.style"; // { Error }
import Footer from "./Components/Footer";

// first-party package imports and styling
import { itemWithId, stringWithId } from "../../state/atoms/withId";
import { objectWithId } from "../../state/atoms/contractState";
import useDocumentTitle from "../../hooks/useDocumentTitle";

// * ===============================================================================================
// * STYLED COMPONENTS =============================================================================
// * ===============================================================================================

export const Purchasing = (): JSX.Element => {
  const err = "Having issues? Try reconnecting your wallet, or use CTRL+F5.";
  const [globalError, setGlobalError] = useRecoilState(
    stringWithId("error", err)
  );
  const [state, setState] = useRecoilState<any>(objectWithId("sale-state"));
  const wallet = useSelector((root: RootStateOrAny) => root.wallet);
  const [contract, setContract] = useState<any | null>(null);
  useDocumentTitle(`${process.env.REACT_APP_PROJECT_TITLE} Mint`);
  const [amount, setAmount] = useRecoilState(itemWithId("purchase-amount"));

  useEffect(() => {
    setContract(wallet?.contract);
    refreshState(contract, wallet, state, setState);

    if (wallet?.type !== "Infura") {
      const interval = setInterval(
        () => refreshState(contract, wallet, state, setState),
        5000
      );

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, contract]);

  return (
    <PurchasingModule>
      <Banner />
      <div>
        <AmountSelectorDiv
          mintNFT={() =>
            mintNFT(
              state,
              amount,
              contract,
              setGlobalError,
              globalError,
              wallet,
              setAmount
            )
          }
        />
        {/* <Error>{globalError}</Error> */}
      </div>
      <Footer />
    </PurchasingModule>
  );
};
