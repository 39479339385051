import { atom } from "recoil";
import { memoize } from "lodash";

export const itemWithId = memoize((id) =>
  atom({
    key: `${id}`,
    default: 0,
  })
);

export const listWithId = memoize((id) =>
  atom({
    key: `${id}`,
    default: [],
  })
);

export const booleanWithId = memoize((id) =>
  atom({
    key: `${id}`,
    default: false,
  })
);

export const stringWithId = memoize((id, initial = "") =>
  atom({
    key: `${id}`,
    default: initial,
  })
);


